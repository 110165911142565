import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Context from "../../Context";
import getUrlParams from "../../util/controllers/getUrlParams";
import { serverLine } from "../../util/controllers/serverLine";
import LoadingSection from "../../util/components/LoadingSection";
import BeautifulTitle from "../../util/components/BeautifulTitle";
import FeedItemDiscussion from "../../util/components/feedItem/FeedItemDiscussion";
import UserBox from "../profilePage/UserBox";
import CustomButton from "../../util/components/inputs/CustomButton";
import ReportBox from "./components/ReportBox";
import FeedItemConclusion from "../../util/components/feedItem/FeedItemConclusion";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 50px;
  width: 45vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Content = styled.div`
  width: 100%;
`;

export default function ItemReportsPage() {
  const { currentRoute, popupAlert } = useContext(Context);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  let urlQuery = getUrlParams(currentRoute);

  let subjectType = urlQuery.get("subjectType");
  subjectType = subjectType.toUpperCase();

  useEffect(() => {
    serverLine
      .get(
        `/reports/?subjectID=${urlQuery.get(
          "subjectID"
        )}&subjectType=${subjectType}`
      )
      .then((newData) => {
        setData(newData);
        setLoading(false);
      });
  }, []);

  if (loading || !data) return <LoadingSection />;

  return (
    <Container>
      <BeautifulTitle>Reports</BeautifulTitle>

      <Main>
        <Content>{getMainContent()}</Content>

        <Buttons>
          <CustomButton variant="filled" onClick={banItem}>
            {data.reportItem.isBanned ? "Un-Ban Item" : "Ban Item"}
          </CustomButton>

          {/* To ban a user a user should be first reported and then banned
              because from the content report page it is hard to check if a user is banned or not  */}
          {/* {urlQuery.get("subjectType") == "CONTENT" ? (
              <CustomButton variant="filled" onClick={banAuthor}>
                Ban Author
              </CustomButton>
            ) : null} */}

          <CustomButton variant="filled" onClick={settleReports}>
            Settle Reports
          </CustomButton>
        </Buttons>

        <List>
          {data.reports.map((item) => (
            <ReportBox item={item} />
          ))}
        </List>
      </Main>
    </Container>
  );

  function getMainContent() {
    if (subjectType == "CONTENT")
      return (
        <FeedItemDiscussion
          colorVariation={"SURFACE"}
          showReportsData={true}
          item={data.reportItem}
        />
      );

    if (subjectType == "CONCLUSION")
      return (
        <FeedItemConclusion
          colorVariation={"SURFACE"}
          showReportsData={true}
          item={data.reportItem}
        />
      );

    if (subjectType == "PROFILE")
      return <UserBox showReportsData={true} item={data.reportItem} />;
  }

  function banItem() {
    serverLine.post("/ban", {
      subjectID: urlQuery.get("subjectID"),
      subjectType: subjectType,
      action: data.reportItem.isBanned ? "UN-BAN" : "BAN",
    });

    let newData = { ...data };

    newData.reportItem.isBanned = !newData.reportItem.isBanned;

    setData(newData);
    popupAlert("Done!");
  }

  function settleReports() {
    serverLine.post("/settle-reports", {
      subjectID: urlQuery.get("subjectID"),
      subjectType: subjectType,
    });

    popupAlert("Reports have been settled");
  }
}
