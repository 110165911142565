import { GrGoogle } from "react-icons/gr";
import CustomButton from "./inputs/CustomButton";
import goToAuthScreen from "../controllers/goToAuthScreen";

export default function LoginButton() {
  return (
    <CustomButton
      icon={<GrGoogle />}
      variant="outlined"
      circular={true}
      style={{ textTransform: "capitalize" }}
      onClick={goToAuthScreen}
    >
      Login With Google
    </CustomButton>
  );
}
