import getServerURL from "../../../../util/controllers/getServerURL";
import { io } from "socket.io-client";
import getFriends from "./getFriends";
import onPingResponse from "./event/onPingResponse";
import onMessage from "./event/onMessage";
import onSeen from "./event/onSeen";
import loadChatGroups from "../api/loadChatGroups";
import loadSelectedChatGroup from "../api/loadSelectedChatGroup";
import onTyping from "./event/onTyping";
import onKickOut from "./event/onKickOut";
import onReaction from "./event/onReaction";

export default function initializeChat() {
  let { setIsConnected, typingData, setTypingData } = window.globalChatSpace;

  let serverURL = getServerURL();

  console.log("IO ServerURL", serverURL);

  let socket = io(serverURL, {
    query: { authToken: localStorage.getItem("cottonchat-token") },
    path: "/cottonchat-socket",
    reconnection: true,
    reconnectionAttempts: 60 * 60 * 24,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    randomizationFactor: 0.5,
  });

  function onConnect() {
    setIsConnected(true);

    //Executes on first connection or connection after disconnection
    //With this chat page data is always up to date

    loadChatGroups();
    loadSelectedChatGroup();
  }

  function onDisconnect() {
    setIsConnected(false);
    //initializeChat();
    //Socket.io automatically handles reconnection
  }

  window.chatSocket = socket;

  socket.on("error", (data) => {
    window.doAlert(data.data);
  });

  socket.on("success", () => {
    if (window.pingInterval) window.clearInterval(window.pingInterval);

    window.pingInterval = setInterval(() => {
      socket.emit("ping", { friends: getFriends() });
    }, 5 * 1000);
  });

  socket.on("reaction", onReaction);
  socket.on("kickOut", onKickOut);
  socket.on("pingResponse", onPingResponse);
  socket.on("typing", onTyping);
  socket.on("seen", onSeen);
  socket.on("connect", onConnect);
  socket.on("disconnect", onDisconnect);
  socket.on("message", onMessage);

  return () => {
    socket.disconnect();
  };
}
