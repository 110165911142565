import { serverLine } from "../../../../../util/controllers/serverLine";
import messageTransactionHappened from "../message/messageTransactionHappened";

export default async function sendMessage({
  messageDoc,
  chatGroup,
  oppositeMember,
}) {
  let chatGroupID = chatGroup._id;
  let { loggedInUser, replyingTo, setReplyingTo } = window.globalChatSpace;

  messageDoc.replyOf = replyingTo;
  setReplyingTo(null);

  messageTransactionHappened({
    sender: loggedInUser,
    sentOrReceived: "SENT",
    messageDoc,
    chatGroup: chatGroup,
    oppositeMember: oppositeMember,
  });

  await serverLine.post("/message", {
    messageDoc,
    chatGroupID: chatGroupID,
  });

  //We send message after saving because of two reasons
  //Enforce blocking
  //Avoid the case where reaction is being saved but the original post is not yet saved
  window.chatSocket.emit("message", {
    messageDoc: messageDoc,
    chatGroupID: chatGroupID,
  });
}
