import styled from "styled-components";
import Link from "../Link";

const BrandContainerMain = styled.div`
  width: 200px;
  height: 50px;
  background: var(--logo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 900px) {
    height: 30px;
    width: 130px;
  }
`;

export default function BrandContainer({ style }) {
  return (
    <Link href="/">
      <BrandContainerMain style={style} />
    </Link>
  );
}
