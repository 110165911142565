export default function onTyping(data) {
  let { selectedChatGroupData, setTypingData, typingData } =
    window.globalChatSpace;

  console.log("typing....", data.hasStopped, data);

  let sender = data.sender;
  let chatGroupID = data.chatGroupID;
  let hasStopped = data.hasStopped;

  if (!typingData) typingData = {};
  typingData = { ...typingData };

  if (selectedChatGroupData) {
    let typingHappenedInSelectedGroup =
      selectedChatGroupData.group._id == chatGroupID;

    if (typingHappenedInSelectedGroup) {
      let senderUserID = sender._id;

      if (hasStopped) {
        if (typingData[senderUserID]) delete typingData[senderUserID];
      } else {
        typingData[senderUserID] = {
          time: new Date().toISOString(),
          sender: sender,
        };
      }

      console.log("new typing data", typingData);

      setTypingData(typingData);
    }
  }
}
