import styled from "styled-components";

import { useContext } from "react";
import Context from "../../../Context.js";

const Container = styled.div`
  height: 100vh;
  height: 100svh;
  width: 100vw;
  overflow: hidden;
  background-color: var(--bgColor);
`;

const Main = styled.div`
  height: 100vh;
  height: 100svh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Background = styled.div`
  height: 100vh;
  height: 100svh;
  width: 100vw;
  background: var(--mainBackground);
  object-fit: cover;

  @media (max-width: 900px) {
    filter: saturate(1.5);
  }
`;

export default function WithBackground({ children }) {
  const { loggedInUserID, isMobile } = useContext(Context);

  // return children;

  return (
    <Container>
      <Main>{children}</Main>
      <Background />
    </Container>
  );
}
