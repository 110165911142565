import goTo from "../../../../util/controllers/goTo";
import { serverLine } from "../../../../util/controllers/serverLine";
import sendLeftChatGroup from "../socket/send/sendLeftChatGroup";
import loadChatGroups from "./loadChatGroups";

export default async function leaveChatGroup(chatGroupID) {
  let { setSelectedChatGroupIsLoading } = window.globalChatSpace;
  setSelectedChatGroupIsLoading(true);
  await serverLine.post(`/leave-chat-group/`, { chatGroupID });
  sendLeftChatGroup(chatGroupID);
  goTo("/chat")();
  loadChatGroups();
  setSelectedChatGroupIsLoading(false);
}
