import styled from "styled-components";
import DownvoteButton from "./DownvoteButton";
import UpvoteButton from "./UpvoteButton";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 5px;
  }
`;

export default function VoteButtons() {
  return (
    <Buttons>
      <UpvoteButton />
      <DownvoteButton />
    </Buttons>
  );
}
