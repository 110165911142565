import { styled } from "styled-components";
import getImageURL from "../../../../../util/controllers/getImageURL";

const Container = styled.div`
  width: 100%;
  background-color: var(--secondarySurface);
  padding: 10px 20px;
  position: relative;
  border-radius: 10px;
  font-size: 13px;
  margin-bottom: 12px;
`;

const ReplyingToImage = styled.img``;

const ReplyingToText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ReplyingToUser = styled.div`
  text-transform: capitalize;
  font-weight: 700;
`;

const ReplyingToMsg = styled.div``;

export default function ReplyOfBox({ item }) {
  if (!item) return null;

  let text = "";
  let image = item.message.image;

  if (image) {
    text = "Photo";
  } else {
    text = item.message.text;
  }

  return (
    <Container>
      {image && <ReplyingToImage src={getImageURL(image)}></ReplyingToImage>}
      <ReplyingToText>
        <ReplyingToUser>{item.messageAuthor.username}</ReplyingToUser>
        <ReplyingToMsg> {text} </ReplyingToMsg>
      </ReplyingToText>
    </Container>
  );
}
