import getUnreadMessageCount from "./getUnreadMessageCount";

export default function calculateUnreadMessagesCount() {
  let { chatGroups } = window.globalChatSpace;
  let count = 0;

  if (!chatGroups) return 0;
  for (let item of chatGroups) {
    count += getUnreadMessageCount(item, true);
  }

  return count;
}
