import goTo from "../../../../../util/controllers/goTo";
import removeGroupFromChatGroupPanel from "../message/removeGroupFromChatGroupPanel";

export default function onKickOut(data) {
  let chatGroup = data.chatGroup;
  let chatGroupID = chatGroup._id;
  let userID = data.senderUserID;

  let { selectedChatGroup, loggedInUserID } = window.globalChatSpace;

  let wasForSelectedChatGroup = false;

  if (selectedChatGroup) {
    if (selectedChatGroup._id == chatGroupID) {
      wasForSelectedChatGroup = true;
      removeMemberFromSelectedChatGroup(userID);
    }
  }

  if (loggedInUserID == userID) {
    removeGroupFromChatGroupPanel(chatGroupID);

    if (wasForSelectedChatGroup) {
      goTo("/chat")();
    }
  }
}

function getIndexOfMember({ membersMembershipData, senderUserID }) {
  let i = 0;
  for (let item of membersMembershipData) {
    if (item.userID == senderUserID) {
      return i;
    }

    i++;
  }

  return false;
}

function removeMemberFromSelectedChatGroup(userID) {
  let { selectedChatGroup, setSelectedChatGroup } = window.globalChatSpace;

  let newSelectedChatGroup = { ...selectedChatGroup };
  let membersMembershipData = [...newSelectedChatGroup.membersMembershipData];

  let indexOfMember = getIndexOfMember({
    membersMembershipData,
    senderUserID: userID,
  });

  if (indexOfMember !== null) {
    membersMembershipData.splice(indexOfMember, 1);
    newSelectedChatGroup.membersMembershipData = membersMembershipData;
    setSelectedChatGroup(newSelectedChatGroup);
  }
}
