export default function generateQueryPath(queryObj) {
  let queryCount = Object.keys(queryObj).length;
  if (!queryCount) return "";

  let i = 0;

  let path = "";

  for (let key in queryObj) {
    let value = queryObj[key];
    if (i == 0) {
      path += `?${key}=${value}`;
    } else {
      path += `&${key}=${value}`;
    }
    i++;
  }

  return path;
}
