import styled from "styled-components";
import FilledButton from "../buttons/FilledButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 20px;
`;

export default function Options({ options }) {
  return (
    <Container>
      {options.map((item) => (
        <FilledButton style={{ width: "100%" }} onClick={item.onClick}>
          {item.label}
        </FilledButton>
      ))}
    </Container>
  );
}
