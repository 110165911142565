import { styled } from "styled-components";
import getImageURL from "../../../../../util/controllers/getImageURL";
import goTo from "../../../../../util/controllers/goTo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  overflow: hidden;
  background-color: var(--secondaryInput);
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const ProfileImage = styled.img`
  height: 80px;
  border-radius: 00px;
  width: 80px;
`;

const AuthorData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AuthorName = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

const AuthorUsername = styled.div`
  font-size: 14px;
`;

const Right = styled.div`
  margin-right: 20px;
  font-size: 50px;
`;

export default function MessageReactionsAuthorList({ item }) {
  if (!item) return null;

  if (!item.reactions) return null;

  let list = [];

  for (let userID in item.reactions) {
    let val = item.reactions[userID];
    let emoji = val.value;
    let author = val.author;

    list.push(
      <Item onClick={goTo("/" + author.username, { isReplace: true })}>
        <Left>
          <ProfileImage src={getImageURL(author.profileImage, true)} />
          <AuthorData>
            <AuthorName>{author.name}</AuthorName>
            <AuthorUsername>@{author.username}</AuthorUsername>
          </AuthorData>
        </Left>

        <Right>{emoji}</Right>
      </Item>
    );
  }

  return <Container>{list}</Container>;
}
