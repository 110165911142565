import { useContext } from "react";
import { styled } from "styled-components";
import Context from "../../../Context";
import PopupSelect from "../PopupSelect";
import usePopupDialog from "../../lib/popupDialog/usePopupDialog";
import FilledButton from "../buttons/FilledButton";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
    gap: 10px;
  }
`;
const Name = styled.div`
  font-size: 19px;
  font-weight: 700;
  /* font-weight: 800; */

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;
const Btn = styled.div`
  padding: 8px 15px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  background: var(--secondaryInput) !important;
  color: var(--inputText);
  border-radius: 10px;
  opacity: 0.9;
  transition: 0.2s ease-in-out;

  @media (max-width: 900px) {
    font-size: 13px;
  }

  &:hover {
    opacity: 1;
    background: var(--primaryInput) !important;
    transform: scale(0.9);
  }

  ${({ isActive }) => {
    if (isActive)
      return `
        opacity: 1;
        background: var(--primaryInput) !important;

    `;
  }}

  ${({ isActive, disableInActive }) => {
    if (!isActive && disableInActive)
      return `
        opacity:0.5;

    `;
  }}
`;
const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  justify-content: center;
  align-items: center;

  /* background: var(--translucent); */

  padding: 7px;

  /* overflow: hidden; */
  /* width: 100%; */
  gap: 5px;
  flex-wrap: wrap;

  ${({ variant }) => {
    if (variant === "fluid")
      return `
      overflow: unset;
      flex-wrap:wrap;
      gap:10px;
    `;
  }}

  @media (max-width:900px) {
    background: unset;
    border: unset;
    padding: 0;
  }
`;

const ButtonLabel = styled.div``;
export default function CustomToggle({
  variant,
  name,
  value,
  onChange,
  options,
  btnStyle,
  toggleStyle,
  toggleContainerStyle,
  colorVariation,
  onHoverStyle,
}) {
  const { closePopup, showPopup } = usePopupDialog();

  let optionsToProcess = options;

  if (!optionsToProcess) {
    if (!value) value = false;

    optionsToProcess = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }

  let limit = 15;

  if (optionsToProcess.length > limit) {
    optionsToProcess = options.slice(0, limit);
    optionsToProcess.push({ label: "More", onClick: showMoreOptionsInPopup });
  }

  let tgl = (
    <Toggle variant={variant} style={toggleStyle}>
      {optionsToProcess.map((item) => {
        return (
          <FilledButton
            onHoverStyle={onHoverStyle}
            colorVariation={colorVariation}
            style={btnStyle}
            key={item.value}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              } else {
                onChange(item.value);
              }
            }}
            highlight={item.value === value}
          >
            {item.label}
          </FilledButton>
        );
      })}
    </Toggle>
  );

  if (!name) return tgl;

  return (
    <Container style={toggleContainerStyle}>
      {name ? <Name>{name}</Name> : null}

      {tgl}
    </Container>
  );

  function showMoreOptionsInPopup() {
    let leftOptions = options.slice(limit, options.length);

    showPopup({
      title: "More Options",
      component: (
        <PopupSelect value={value} onChange={onChange} options={leftOptions} />
      ),
    });
  }
}
