import MaterialInput from "../MaterialInput";

export default function SocialLinksEditor({ value, onChange }) {
  if (!value) value = {};
  return (
    <>
      <MaterialInput
        value={value.instagram}
        onChange={update("instagram")}
        label={"Instagram Username"}
      />

      <MaterialInput
        value={value.twitter}
        onChange={update("twitter")}
        label={"Twitter Username"}
      />
    </>
  );

  function update(field) {
    return (e) => {
      let inputVal = e.target.value;

      let newValue = { ...value };
      newValue[field] = inputVal;
      onChange(newValue);
    };
  }
}
