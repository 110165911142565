import { serverLine } from "../../../../util/controllers/serverLine";

//Clean load means
//Current data will be erased and then new data will be loaded
export default function loadChatGroups() {
  let {
    chatGroupsType,
    setChatGroups,
    setMutedChatGroups,
    setChatMembershipAndNotifications,
    setChatGroupsAreLoading,
    setOppositeMembers,
  } = window.globalChatSpace;

  setChatGroupsAreLoading(true);

  let url = "/chat-groups";

  if (chatGroupsType == "MUTED") url += "/?onlyMuted=true";

  serverLine
    .get(url)
    .then((newData) => {
      let { chatMembershipAndNotifications, chatGroups, oppositeMembers } =
        newData;

      setOppositeMembers(oppositeMembers);
      setChatMembershipAndNotifications(chatMembershipAndNotifications);

      if (chatGroupsType == "MUTED") {
        setMutedChatGroups(chatGroups);
      } else {
      }
      setChatGroups(chatGroups);

      setChatGroupsAreLoading(false);
    })
    .catch((e) => {
      window.popupAlert(e.message);
    });
}
