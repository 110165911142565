import styled from "styled-components";
import capitalizeFirstLetter from "../../util/controllers/capitalizeFirstLetter";
import getDateString from "../../util/controllers/getDateString";
import getImageURL from "../../util/controllers/getImageURL";

const Notification = styled.div`
  background: var(--secondarySurface);
  box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 25px 25px;
  width: 100%;
  border-radius: 15px;
  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${({ seen }) => {
    if (!seen)
      return `
      background: var(--primarySurface);`;
  }}
`;

const NotificationText = styled.div`
  /* text-transform: capitalize; */
  font-size: 19px;
`;

const NotificationTextSmall = styled.div`
  /* text-transform: capitalize; */
  font-size: 15px;
  margin-top: -10px;
  opacity: 0.5;
`;

const NotificationButtons = styled.div`
  display: flex;
  flex-direction: row;

  gap: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Image = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 100px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function NotificationBox({
  seen,
  image,
  text,
  buttons,
  createdAt,
}) {
  return (
    <Notification seen={seen}>
      <Row>
        {image ? <Image src={getImageURL(image)} /> : null}

        <Right>
          <NotificationText>{capitalizeFirstLetter(text)}</NotificationText>
          <NotificationTextSmall>
            {getDateString(createdAt)}
          </NotificationTextSmall>
          <NotificationButtons>{buttons}</NotificationButtons>
        </Right>
      </Row>
    </Notification>
  );
}
