import { styled } from "styled-components";
import LoginButton from "../LoginButton";

const Container = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

const Title = styled.div`
  font-size: 21px;
  opacity: 0.7;
  text-transform: capitalize;
`;
//100 tokens

export default function PopupLogin() {
  return (
    <Container>
      <LoginButton />
    </Container>
  );
}
