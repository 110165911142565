import { styled } from "styled-components";
import BrandContainer from "../brand/BrandContainer.js";

import { AiOutlineCheck, AiOutlineNodeExpand } from "react-icons/ai";
import CustomButton from "../inputs/CustomButton.js";

// import BasicInfoEditor from "../BasicInfoEditor.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 25px;
  height: 100vh;
  height: 100svh;
  overflow-y: scroll;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 20px 35px;
  border-radius: 15px;

  background: var(--primarySurface);
  width: 35vw;
  box-shadow: rgb(0 0 0 / 5%) 1px 4px 20px 1px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const BrandGlass = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px 15px;
  border-radius: 15px;

  width: 35vw;

  @media (max-width: 900px) {
    width: 62vw;
  }
`;

const SubmitButton = styled.div`
  background: var(--translucent);
  padding: 15px 50px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  font-size: 21px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 21px;
  padding: 0;
  font-weight: 400;
  margin: 50px 0;
  font-family: "Montserrat", sans-serif;
  opacity: 0.7;
  margin-top: 10px;
`;

export default function OnboardingBoilerplate({ onNext, title, children }) {
  return (
    <Container>
      <BrandGlass>
        <BrandContainer />
      </BrandGlass>
      <Main>
        {title ? (
          <>
            <Title>{title}</Title>
          </>
        ) : null}

        {children}

        <br />
        {onNext ? (
          <CustomButton
            icon={<AiOutlineCheck />}
            width="100%"
            onClick={onNext}
            variant={"outlined"}
          >
            Next
          </CustomButton>
        ) : null}

        <br />
      </Main>
    </Container>
  );
}
