import { useContext } from "react";
import Context from "../../../Context";
import styled from "styled-components";
import FilledButton from "../buttons/FilledButton";
import goToAuthScreen from "../../controllers/goToAuthScreen";
import BrandContainer from "../brand/BrandContainer";
import { GrGoogle } from "react-icons/gr";
import { RiChat3Line, RiHomeLine, RiNotificationLine } from "react-icons/ri";
import { FiAlertTriangle } from "react-icons/fi";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import calculateUnreadMessagesCount from "../../../pages/chatPage/controllers/ui/calculateUnreadMessagesCount";

const MobileTopNav = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  background: transparent;
  border: none;
  width: 100%;
  font-family: "Raleway";
  background: var(--primarySurface);
  align-items: center;
  padding: 0 30px;
  height: 70px;
  z-index: 66;
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid var(--mobileHeaderBorder);
`;

const Items = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  padding-bottom: 30px;
  align-items: center;

  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: row;
    padding: 0 10px;
    gap: 0;
    width: auto;
    justify-content: space-between;
    /* width: 100%; */
  }
`;

const NotificationBubble = styled.div`
  display: flex;
  position: absolute;
  right: -30px;
  /* top: 15px; */
  padding: 0;
  border-radius: 500px;
  height: 25px;
  padding: 0 10px;
  width: auto;
  font-size: 12px;
  background-color: #145c96;
  color: var(--bgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  @media (max-width: 900px) {
    right: -10px;
    top: -10px;
    font-size: 10px;
    padding: 0 7px;
    height: 20px;
    font-weight: 900;
  }
`;

const NotificationBubbleAlert = styled(NotificationBubble)`
  /* color: tomato; */
  background-color: tomato;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 35px 0;
  align-items: center;

  width: 100%;

  background: var(--primarySurface);
  border-bottom: 1px solid var(--foreground);

  @media (max-width: 900px) {
    background: transparent;
    border: none;
    width: auto;
  }
`;

const NavIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7vw;
`;

const MobileLogo = styled.div`
  width: 90px;
  height: 30px;
  background: var(--textLogo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export default function MobileHeader() {
  const { loggedInUserID, notificationData } = useContext(Context);
  if (!loggedInUserID)
    return (
      <MobileTopNav>
        <Logo>
          <BrandContainer />
        </Logo>
        <Items>
          <FilledButton
            style={{ borderRadius: "10px" }}
            variant="filled"
            onClick={goToAuthScreen}
            icon={<GrGoogle />}
          >
            Login / Signup
          </FilledButton>
        </Items>
      </MobileTopNav>
    );

  let unreadMessages = calculateUnreadMessagesCount();

  return (
    <MobileTopNav>
      <Buttons>
        <NavIconButton href={`/home`}>
          <RiHomeLine />
        </NavIconButton>

        <NavIconButton href={`/notifications`}>
          <RiNotificationLine />

          {notificationData.general ? (
            <NotificationBubble>{notificationData.general}</NotificationBubble>
          ) : null}
        </NavIconButton>
      </Buttons>

      <Logo>
        <MobileLogo />
      </Logo>

      <Buttons>
        <NavIconButton href={`/chat`}>
          <RiChat3Line />

          {unreadMessages ? (
            <NotificationBubble>{unreadMessages}</NotificationBubble>
          ) : null}
        </NavIconButton>
        <NavIconButton href={`/mobile-more`}>
          <HiOutlineMenuAlt4 />
        </NavIconButton>
      </Buttons>
    </MobileTopNav>
  );
}
