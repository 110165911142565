import goTo from "../../../../util/controllers/goTo";
import sendJoinedChatGroup from "../socket/send/sendJoinedChatGroup";
import getChatGroup from "./getChatGroup";

export default async function openChat({ chatGroupType, contentID, userID }) {
  let grp = await getChatGroup({ chatGroupType, contentID, userID });

  let chatGroupID = grp._id;

  goTo("/chat/" + chatGroupID + "/?popup=chat")();
  sendJoinedChatGroup(chatGroupID);
}
