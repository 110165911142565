import { useContext } from "react";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import Context from "../../../Context";
import goTo from "../../controllers/goTo";

const Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: row;
  padding: 10px 18px;
  border-radius: var(--borderRadius);
  gap: 10px;
  font-size: 15px;
  cursor: pointer;
  transition: 0.15s ease-in-out;

  text-decoration: none;

  ${({ $backgroundColor, $hoverColor, $onHoverStyle }) => {
    return `
      background: ${$backgroundColor};
      &:hover{
        transform: scale(0.95);
        background: ${$hoverColor};
        ${$onHoverStyle ? $onHoverStyle : ""}
      };
    `;
  }}

  ${({ $highlight, $highlightColor }) => {
    if ($highlight)
      return `
          background: ${$highlightColor};
    `;
  }}


  @media (max-width: 900px) {
    font-size: 12px;
    border-radius: 10px;
    padding: 10px 13px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inputText);
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inputText);
`;

export default function FilledButton({
  children,
  icon,
  onClick,
  highlight,
  style,
  mobileStyle,
  isLoading,
  colorVariation,
  href,
  onHoverStyle,
  textStyle,
}) {
  const { isMobile } = useContext(Context);

  if (!style) style = {};
  if (isMobile) style = { ...style, ...mobileStyle };
  if (isLoading) icon = <MoonLoader size={13} />;

  let backgroundColor = "var(--secondaryInput)";
  let highlightColor = "var(--primaryInput)";
  let hoverColor = "var(--primaryInput)";

  if (colorVariation == "SURFACE") {
    backgroundColor = "var(--secondarySurface)";
    highlightColor = "var(--primarySurface)";
    hoverColor = "var(--primarySurface)";
  }

  if (!onClick && href) {
    onClick = (e) => {
      e.preventDefault();
      goTo(href)();
      return false;
    };
  }

  return (
    <Container
      $onHoverStyle={onHoverStyle}
      href={href}
      $hoverColor={hoverColor}
      $backgroundColor={backgroundColor}
      $highlightColor={highlightColor}
      //
      onClick={onClick}
      $highlight={highlight}
      style={style}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      {children && <TextContainer style={textStyle}>{children}</TextContainer>}
    </Container>
  );
}
