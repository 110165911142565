import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context";
import LoadingSection from "../../util/components/LoadingSection";
import ContentFeed from "../homePage/components/feed/ContentFeed";
import FeedItemDiscussion from "../../util/components/feedItem/FeedItemDiscussion";
import capitalizeFirstLetter from "../../util/controllers/capitalizeFirstLetter";
import ContentPageConclusions from "./ContentPageConclusions";

const Info = styled.div`
  margin-top: 50px;
  opacity: 0.5;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  width: 100%;
`;

const ContentPart = styled.div`
  display: flex;
  flex-direction: column;
  width: 38vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 70px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Hr = styled.div`
  display: flex;
  width: 62vw;
  background: var(--accentColor);
  height: 1px;
  opacity: 0.5;
`;

export default function RenderContent({ postData }) {
  const { loggedInUserID } = useContext(Context);
  if (!postData) return null;
  if (postData.loading) return <LoadingSection />;
  if (postData.isArchived) return <Info>Content has been deleted.</Info>;

  let content = <FeedItemDiscussion item={postData} forContentPage={true} />;

  if (postData.isBanned) {
    if (postData.authorUserID === loggedInUserID) {
      content = (
        <>
          <Info>Your content has been banned.</Info>
          <br />
          <br /> <br /> <br />
          {content}
        </>
      );
    } else {
      content = (
        <>
          <Info>{postData.title}</Info>
          <Info>This content has been banned.</Info>
        </>
      );
    }
  }

  return (
    <Container>
      <Main>
        <ContentPart>{content}</ContentPart>

        <ContentPageConclusions contentID={postData._id} />
      </Main>
    </Container>
  );
}
