import { useContext } from "react";

import Context from "../../Context.js";

import styled from "styled-components";

import getSubPath from "../controllers/getSubPath.js";

import pathVsComp from "../../data/pathVsComp.js";
import PopupPage from "../components/PopupPage.js";
import ProfilePage from "../../pages/profilePage/ProfilePage.js";
import WithHeader from "../components/core/WithHeader.js";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default function CustomRouter() {
  const { currentRoute, nonPopupRoute } = useContext(Context);

  let popupComp = null;
  let baseComp = findCompOfPath(currentRoute);
  let currentPageIsPopup = checkIsPopup(currentRoute);

  if (currentPageIsPopup && nonPopupRoute) {
    if (currentRoute !== nonPopupRoute) {
      baseComp = findCompOfPath(nonPopupRoute);
      popupComp = getPopupComp(currentRoute);
    }
  }

  let containerStyle = null;
  if (popupComp) containerStyle = { opacity: 1 };

  return (
    <>
      <Container data-2={currentPageIsPopup} style={containerStyle}>
        {baseComp ? <WithHeader>{baseComp}</WithHeader> : null}
      </Container>
      {popupComp}
    </>
  );

  function findCompOfPath(pathToScan) {
    pathToScan = pathToScan.split("?")[0];
    if (pathToScan == "/") return pathVsComp["/"];

    for (let aPath in pathVsComp) {
      if (aPath == "/") continue;
      let theComp = pathVsComp[aPath];
      let matchLength = aPath.length;
      let extracted = pathToScan.slice(0, matchLength);
      if (extracted === aPath) return theComp;
    }

    let userPageParam = getSubPath(0, pathToScan);

    return <ProfilePage username={userPageParam} />;
  }

  function getPopupComp(pathName) {
    let isPopup = checkIsPopup(pathName);
    if (isPopup) {
      let element = findCompOfPath(pathName);

      return <PopupPage element={element} />;
    } else {
      return false;
    }
  }
}

function checkIsPopup(path) {
  return path.indexOf("popup=") !== -1;
}
