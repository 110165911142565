import sendIsSeenMessage from "../send/sendIsSeenMessage";

export default function addMessageToTheUI({
  chatGroupID,
  messageDoc,
  sender,
  sentOrReceived,
}) {
  const { selectedChatGroupData, setSelectedChatGroupData } =
    window.globalChatSpace;

  if (sentOrReceived === "RECEIVED") sendIsSeenMessage(chatGroupID);

  let oldSelectedGrpData = { ...selectedChatGroupData };

  oldSelectedGrpData.messages.push({
    ...messageDoc,
    createdAt: new Date().toISOString(),
    messageAuthor: sender,
    authorUserID: sender._id,
    isNew: sentOrReceived == "SENT" ? true : false,
  });

  setSelectedChatGroupData(oldSelectedGrpData);
}
