import styled from "styled-components";
import { useContext, useEffect, useState } from "react";

import Context from "../../Context.js";
import { serverLine } from "../../util/controllers/serverLine.js";

import LoadingSection from "../../util/components/LoadingSection.js";
import BrandContainer from "../../util/components/brand/BrandContainer.js";

import getUrlParams from "../../util/controllers/getUrlParams.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* margin-top: 100px; */
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 100vw;
`;

const ErrorBox = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;

  border-radius: 15px;

  background: var(--primarySurface);
  border: 1px solid var(--foreground);
`;

function SendAuthCode() {
  const searchParams = getUrlParams();
  let { popupAlert } = useContext(Context);
  let [error, setError] = useState(false);

  useEffect(() => {
    console.log("req sending auth code");
    sendCode();
  }, []);

  if (error) return <ErrorBox>{error}</ErrorBox>;
  return (
    <Container>
      <Logo>
        <BrandContainer />
      </Logo>

      <LoadingSection />
    </Container>
  );

  function done(data) {
    if (data) {
      localStorage.setItem("cottonchat-token", data.token);
      localStorage.setItem("cottonchat-userID", data.userID);
      window.location = "/";
    }
  }

  function sendCode() {
    console.log("sending auth code");
    let location = window.location.origin + window.location.pathname;
    let code = searchParams.get("code");

    serverLine
      .post("/cookie", { code, location })
      .then(done)
      .catch(({ message }) => {
        if (typeof message == "object") message = JSON.stringify(message);
        setError(message);
      });
  }
}

export default SendAuthCode;
