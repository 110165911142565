export default function readTypingData() {
  let { typingData, selectedChatGroupData } = window.globalChatSpace;
  if (!typingData) return null;
  if (!selectedChatGroupData) return null;

  for (let memberID in typingData) {
    // return memberID;
    let memberIDs = selectedChatGroupData.membersMembershipData.map(
      (item) => item.userID
    );
    let isMember = memberIDs.includes(memberID);

    if (!isMember) continue;
    let memberTypingData = typingData[memberID];

    let lastTypedAt = new Date(memberTypingData.time).getTime();
    let now = new Date().getTime();

    let typingDataIsFresh = now - lastTypedAt < 3000;

    // return `Typing ${now - lastTypedAt}`;

    if (typingDataIsFresh) {
      if (selectedChatGroupData.group.type == "DIRECT_CHAT") {
        return `Typing....`;
      } else {
        let firstName = memberTypingData.sender.name.split(" ")[0];
        return `${firstName} is typing....`;
      }
    }
  }
}
