import { serverLine } from "../../../../util/controllers/serverLine";

export default async function getChatGroup({
  contentID,
  userID,
  chatGroupType,
}) {
  let chatGroup = await serverLine.get(
    `/chat-group/?contentID=${contentID}&userID=${userID}&chatGroupType=${chatGroupType}`
  );

  return chatGroup;
}
