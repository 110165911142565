export default function removeGroupFromChatGroupPanel(chatGroupID) {
  let { chatGroups, setChatGroups } = window.globalChatSpace;

  let newGroups = [...chatGroups];

  let indexToRemove = null;

  let i = 0;
  for (let item of newGroups) {
    if (item._id == chatGroupID) {
      indexToRemove = i;
    }

    i++;
  }

  if (indexToRemove !== null) {
    newGroups.splice(indexToRemove, 1);
    setChatGroups(newGroups);
  }
}
