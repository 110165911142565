import onChangeStop from "../../../../../util/controllers/onChangeStop";

export default function sendTypingData({
  selectedChatGroupData,
  typingDataSentAt,
  setTypingDataSentAt,
}) {
  onChangeStop(
    selectedChatGroupData.group.chatGroupID,
    () => {
      sendTypingMessageToSocket({ selectedChatGroupData, hasStopped: true });
    },
    700
  );

  if (typingDataSentAt) {
    let lastTypedAt = new Date(typingDataSentAt).getTime();
    let now = new Date().getTime();

    let olderThanThreeSeconds = now - lastTypedAt > 3;
    if (!olderThanThreeSeconds) return;
  }

  setTypingDataSentAt(new Date().toISOString());
  sendTypingMessageToSocket({ selectedChatGroupData });
}

function sendTypingMessageToSocket({
  selectedChatGroupData,
  hasStopped = false,
}) {
  if (window.chatSocket) {
    window.chatSocket.emit("typing", {
      hasStopped: hasStopped,
      chatGroupID: selectedChatGroupData.group._id,
    });
  }
}
