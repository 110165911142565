import styled from "styled-components";
import { useContext, useState } from "react";

import parseScrollData from "../../util/controllers/parseScrollData";
import LoadingSection from "../../util/components/LoadingSection";
import NotificationsCore from "./NotificationsCore";
import Context from "../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  gap: 25px;
  align-items: center;
`;

const Title = styled.div`
  text-align: center;
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function NotificationsPage() {
  let { loggedInUser } = useContext(Context);
  const [scrollData, setScrollData] = useState(null);

  if (!loggedInUser) return <LoadingSection />;

  return (
    <Container onScroll={parseScrollData(setScrollData)}>
      <Main>
        <Title>Notifications</Title>
        <NotificationsCore scrollData={scrollData} />
        <br /> <br /> <br />
      </Main>
    </Container>
  );
}
