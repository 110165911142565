import styled from "styled-components";
import CustomButton from "./inputs/CustomButton";
import BrandContainer from "./brand/BrandContainer";
import goTo from "../controllers/goTo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  justify-content: center;
  padding: 30px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

export default function PageNotFound({ message }) {
  if (!message) message = "Page not found";

  return (
    <Container>
      <br />
      <BrandContainer />
      <br />
      <h1>{message}</h1>

      <Buttons>
        <CustomButton
          semanticHref={"/"}
          onClick={goTo("/", {
            // isAbsolute: true,
            // openInSamePage: true,
          })}
        >
          Go Home
        </CustomButton>
      </Buttons>
    </Container>
  );
}
