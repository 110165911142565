import { styled } from "styled-components";
import { useContext, useState } from "react";
import { MdLightMode } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import logout from "../../util/controllers/logout";
import { LiaBanSolid } from "react-icons/lia";
import { BiLock, BiLogOut } from "react-icons/bi";
import { GoMoon, GoReport } from "react-icons/go";
import adminAccess from "../../data/adminAccess";
import LoadingSection from "../../util/components/LoadingSection";
import Context from "../../Context";
import FilledButton from "../../util/components/buttons/FilledButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
  /* height: 400px; */
`;
const Title = styled.div`
  font-size: 21px;
  text-align: center;
  font-weight: 300;
`;
const Types = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  gap: 25px;
`;

const Message = styled.div`
  margin-top: 200px;
`;

let variant = "filled";

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default function MoreMenuPage() {
  const { loggedInUser, loggedInUserID, colorMode, updateColorMode } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  let isAdmin = false;

  if (loggedInUser) {
    isAdmin = adminAccess.includes(loggedInUser.email);
  }

  if (!loggedInUserID) return <Message>Login to continue</Message>;

  if (loading || !loggedInUser) return <LoadingSection />;

  return (
    <Container>
      <Menu>
        <Title>More</Title>

        <Types>
          <FilledButton
            href="/edit-profile"
            icon={<AiOutlineEdit />}
            colorVariation={"SURFACE"}
          >
            Edit Profile
          </FilledButton>

          <FilledButton
            colorVariation={"SURFACE"}
            href="/blocklist"
            icon={<BiLock />}
          >
            Blocklist
          </FilledButton>

          {isAdmin ? (
            <>
              <FilledButton
                colorVariation={"SURFACE"}
                href="/manage-reported-items"
                icon={<GoReport />}
              >
                Reported Items
              </FilledButton>

              <FilledButton
                colorVariation={"SURFACE"}
                href="/manage-bans"
                icon={<LiaBanSolid />}
              >
                Banned Items
              </FilledButton>
            </>
          ) : null}

          <FilledButton
            colorVariation={"SURFACE"}
            onClick={() => {
              updateColorMode(colorMode == "DARK" ? "LIGHT" : "DARK");
            }}
            icon={colorMode == "DARK" ? <MdLightMode /> : <GoMoon />}
          >
            {colorMode == "DARK" ? "Light Mode" : "Dark Mode"}
          </FilledButton>

          <FilledButton
            colorVariation={"SURFACE"}
            isLoading={loadingLogout}
            onClick={doLogout}
            icon={<BiLogOut />}
          >
            Logout
          </FilledButton>
        </Types>
      </Menu>
    </Container>
  );

  async function doLogout() {
    setLoadingLogout(true);
    logout();
  }
}
