import getOppositeMemberID from "../../../../util/controllers/getOppositeMemberID";

export default function getFriends() {
  const { chatGroups, loggedInUserID } = window.globalChatSpace;

  if (!chatGroups) return [];

  let friends = [];

  for (let item of chatGroups) {
    if (item.type == "CONTENT_CHAT") continue;

    let oppositeMemberID = getOppositeMemberID({
      chatGroup: item,
      loggedInUserID,
    });

    if (!friends.includes(oppositeMemberID)) {
      friends.push(oppositeMemberID);
    }
  }

  return friends;
}
