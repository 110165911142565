import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../../../Context";
import { BsChevronLeft, BsThreeDotsVertical } from "react-icons/bs";
import OnlineBubble from "../common/OnlineBubble";

import readTypingData from "../../controllers/ui/readTypingData";
import getChatUiData from "../../controllers/ui/getChatUiData";
import getOppositeMemberID from "../../../../util/controllers/getOppositeMemberID";
import loadSelectedChatGroup from "../../controllers/api/loadSelectedChatGroup";

import leaveChatGroup from "../../controllers/api/leaveChatGroup";
import BlockUnblockInterface from "../../../profilePage/BlockUnblockInterface";

import limitStringLength from "../../../../util/controllers/limitStringLength";
import getOnlineOfflineStatus from "../../../../util/controllers/socket/getOnlineOfflineStatus";
import SemanticButton from "../../../../util/components/SemanticButton";
import getProfileLink from "../../../../util/controllers/getProfileLink";
import goToProfile from "../../../../util/controllers/goToProfile";
import CustomButton from "../../../../util/components/inputs/CustomButton";

import goTo from "../../../../util/controllers/goTo";
import reachChatPanelEnd from "../../../../util/controllers/reachChatPanelEnd";
import getImageURL from "../../../../util/controllers/getImageURL";
import usePopupDialog from "../../../../util/lib/popupDialog/usePopupDialog";
import Options from "../../../../util/components/inputs/Options";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import DropDownLocationInput from "../../../../util/components/DropDownLocationInput";
import DropDownInput from "../../../../util/components/inputs/DropDownInput";
import muteSelectedChatGroup from "../../controllers/api/muteSelectedChatGroup";
import getChatGroupMuteStatus from "../../controllers/ui/getChatGroupMuteStatus";

const TopPart = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid var(--secondaryInput);
  width: 100%;

  @media (max-width: 900px) {
    gap: 10px;
    padding: 15px;
  }
`;

const TopProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const TopProfileLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  transition: all 0.15s ease-in;
`;

const Image = styled.img`
  height: 47px;
  width: 47px;
  object-fit: cover;
  border-radius: 50px;
`;
const Name = styled.div`
  font-weight: 800;
  font-size: 15px;
  text-transform: capitalize;
  /* white-space: nowrap; */
  width: 100%;
  overflow: hidden;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.7;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
`;

const Username = styled.div`
  &:hover {
    text-decoration: underline;
    /* transform: scale(0.9); */
  }
`;

const BackButton = styled.div`
  opacity: 0.5;
  display: none;
  @media (min-width: 900px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const HeadingInfo = styled.div``;

const rightPanelOptions = [
  {
    label: "Messages",
    value: "MESSAGES",
  },
  {
    label: "Conclusions",
    value: "CONCLUSIONS",
  },
  {
    label: "Members",
    value: "MEMBERS",
  },
  {
    label: "Options",
    value: "OPTIONS",
  },
];

export default function ChatOptionsSection() {
  const { popupAlert, isMobile } = useContext(Context);
  const { loggedInUserID } = useContext(Context);

  const { showPopup } = usePopupDialog();

  const { selectedChatGroupID, selectedChatGroupData } = window.globalChatSpace;

  let oppositeMember = selectedChatGroupData.oppositeMember;

  let options = [];

  let muteStatus = getChatGroupMuteStatus();

  options = [
    {
      label: muteStatus == "MUTED" ? "Unmute" : "Mute",
      onClick: () => {
        muteSelectedChatGroup();
      },
    },
  ];

  if (selectedChatGroupData.group.type == "DIRECT_CHAT") {
    options.push({
      label: "Manage Blocking",
      onClick: manageBlocking,
    });
  } else {
    options.push({
      label: "Leave Room",
      onClick: leaveRoom,
    });
  }

  return <Options options={options} />;

  function leaveRoom() {
    leaveChatGroup(selectedChatGroupData.group._id);
  }

  function manageBlocking() {
    let receiverUserData = oppositeMember;

    showPopup({
      title: "Manage Blocking",
      component: (
        <BlockUnblockInterface
          receiverUserData={receiverUserData}
          receiverUserID={receiverUserData._id}
          senderUserID={loggedInUserID}
          callback={() => {
            loadSelectedChatGroup(selectedChatGroupID);
          }}
        />
      ),
    });
  }
}
