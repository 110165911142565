import styled from "styled-components";

const Anchor = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color);
`;

export default function SemanticButton({
  onClick,
  semanticHref,
  children,
  style,
}) {
  return (
    <Anchor
      style={style}
      href={semanticHref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        return false;
      }}
    >
      {children}
    </Anchor>
  );
}
