import { useState } from "react";
import PopupDialogContext from "./PopupDialogContext";
import PopupDialog from "./PopupDialog";
import getUrlQuery from "../../controllers/getUrlQuery";
import goTo from "../../controllers/goTo";
import generateQueryPath from "./controllers/generateQueryPath";

export default function PopupDialogProvider({ children }) {
  const [popupComponent, setPopupComponent] = useState(null);
  window.showPopup = showPopup;
  window.popupComponent = popupComponent;

  console.log(popupComponent);

  let state = { popupComponent, showPopup, closePopup, setPopupComponent };
  return (
    <PopupDialogContext.Provider value={state}>
      {children}
      <PopupDialog />
    </PopupDialogContext.Provider>
  );

  function showPopup(newValue) {
    console.log("setting", newValue);
    setPopupComponent(newValue);

    let currentQueryObj = getUrlQuery();

    if (!currentQueryObj.popupDialog) {
      let currentPath = window.location.pathname;
      currentQueryObj.popupDialog = true;

      let newQueryPath = generateQueryPath(currentQueryObj);
      goTo(currentPath + newQueryPath)();
    }
  }

  function closePopup() {
    goTo(-1)();
  }
}
