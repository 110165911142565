import { serverLine } from "./serverLine.js";

function logout() {
  localStorage.removeItem("cottonchat-token");
  localStorage.removeItem("cottonchat-userID");
  localStorage.removeItem("cottonchat-color-mode");
  window.location = window.location.origin;

  // serverLine.delete("/cookie").then(() => {
  // window.location = window.location.origin;
  // });
}

export default logout;
