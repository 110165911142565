let contentCategories = {
  GENERAL: [
    //Broad

    //Work related
    { label: "General", value: "GENERAL" },
    { label: "Programming", value: "PROGRAMMING" },
    { label: "Startup", value: "STARTUP" },
    { label: "News", value: "NEWS" },
    { label: "Sports", value: "SPORTS" },
    //Entertainment
    { label: "Art", value: "ART" },
    { label: "Maths", value: "MATHEMATICS" },
    { label: "Science", value: "SCIENCE" },
    { label: "Humour", value: "HUMOUR" },
    { label: "Anime", value: "ANIME" },
    { label: "Movies", value: "MOVIES" },
    { label: "Music", value: "MUSIC" },
    { label: "Geopolitics", value: "GEOPOLITICS" },
    { label: "Video Games", value: "VIDEO_GAMES" },
  ],
};

export default contentCategories;
