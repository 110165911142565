import { styled } from "styled-components";
import getImageURL from "../../../../util/controllers/getImageURL";
import { GrClose } from "react-icons/gr";

const Container = styled.div`
  width: 100%;
  background-color: var(--primaryInput);
  padding: 10px;
  position: relative;
  border-radius: 10px;
`;

const ReplyingToImage = styled.img``;

const ReplyingToText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ReplyingToUser = styled.div`
  text-transform: capitalize;
  font-weight: 700;
`;

const ReplyingToMsg = styled.div``;

const RemoveButton = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--secondaryInput);
  padding: 10px;
  font-size: 13px;
  border-radius: 20px;
  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    /* background-color: var(--inputText); */
    /* color: var(--secondaryInput); */
    transform: scale(0.9);
  }
`;

export default function ReplyingToInfo() {
  let { replyingTo, setReplyingTo } = window.globalChatSpace;

  if (!replyingTo) return null;

  let text = "";
  let image = replyingTo.message.image;

  if (image) {
    text = "Photo";
  } else {
    text = replyingTo.message.text;
  }

  return (
    <Container>
      {image && <ReplyingToImage src={getImageURL(image)}></ReplyingToImage>}
      <ReplyingToText>
        <ReplyingToUser>{replyingTo.messageAuthor.username}</ReplyingToUser>
        <ReplyingToMsg> {text} </ReplyingToMsg>
      </ReplyingToText>
      <RemoveButton
        onClick={() => {
          setReplyingTo(null);
        }}
      >
        <GrClose />
      </RemoveButton>
    </Container>
  );
}
