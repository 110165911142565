export default function getChatGroupMuteStatus() {
  let { selectedChatGroupData } = window.globalChatSpace;

  if (!selectedChatGroupData) return "NOT_MUTED";

  let myMembership = getMyMembershipData();
  if (!myMembership) return "NOT_MUTED";

  if (myMembership.isMuted) {
    return "MUTED";
  } else {
    return "NOT_MUTED";
  }
}

function getMyMembershipData() {
  let { selectedChatGroupData, loggedInUserID } = window.globalChatSpace;

  for (let item of selectedChatGroupData.membersMembershipData) {
    if (item.userID == loggedInUserID) return item;
  }

  return null;
}
