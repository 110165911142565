import { styled } from "styled-components";
import goTo from "../../util/controllers/goTo";
import { serverLine } from "../../util/controllers/serverLine";
import { useContext, useEffect, useState } from "react";

import Context from "../../Context";

import {
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineSend,
  AiOutlineSetting,
} from "react-icons/ai";

import { ShimmerThumbnail } from "react-shimmer-effects";
import { MoonLoader } from "react-spinners";
import contentCategories from "../../data/contentCategories";
import DropDownInput from "../../util/components/inputs/DropDownInput";
import FilledButton from "../../util/components/buttons/FilledButton";
import MaterialInput from "../../util/components/MaterialInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  overflow: hidden;
  width: 38vw;
  padding: 0;
  margin-top: 30px;
  border-radius: 10px;
  background: var(--primarySurface);

  box-shadow: 1px 1px 20px 1px #00000024;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  /* align-items: center; */
  width: 100%;
  height: 75px;
  padding: 0 20px;
  justify-content: space-between;

  border-top: 1px solid var(--translucent);
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;

  gap: 10px;
`;

const SelectCategoryContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-left: 20px;
  padding-right: 200px;
  border-right: 1px solid var(--translucentHard);
`;

const WriteTitle = styled.textarea`
  height: 100px;
  resize: none;
  width: 100%;
  border: none;
  border-radius: 10px;
  color: var(--color);
  padding: 15px;
  background-color: var(--secondaryInput);
  font-size: 19px;
  font-weight: 700;
  /* text-align: center; */

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

const WriteDescription = styled.textarea`
  background-color: var(--secondaryInput);
  height: 180px;
  resize: none;
  width: 100%;
  border: none;
  color: var(--color);
  padding: 15px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

const Inputs = styled.div`
  padding: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

let maxTitleLength = 50;
let maxDescriptionLength = 500;

const Warning = styled.div`
  color: tomato;
  width: 100%;
  /* text-align: ; */
  padding-left: 20px;
`;

const Settings = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding: 0 15px;
`;

export default function CreateContent({ contentToUpdate }) {
  const { loggedInUser, popupAlert } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  //
  const [maxMembersPerRoom, setmaxMembersPerRoom] = useState(5);
  const [maxRoomsAutoAddAuthorTo, setMaxRoomsAutoAddAuthorTo] = useState(10);

  let type = "GENERAL";

  if (contentToUpdate) type = contentToUpdate.type;

  useEffect(() => {
    if (contentToUpdate) {
      setTitle(contentToUpdate.title);
      setCategory(contentToUpdate.category);
      if (contentToUpdate.maxMembersPerRoom)
        setmaxMembersPerRoom(contentToUpdate.maxMembersPerRoom);
      if (contentToUpdate.maxRoomsAutoAddAuthorTo)
        setMaxRoomsAutoAddAuthorTo(contentToUpdate.maxRoomsAutoAddAuthorTo);
    }
  }, []);

  console.log("contentToUpdate", contentToUpdate, title);

  if (!loggedInUser)
    return <ShimmerThumbnail height={250} width={"100%"} rounded />;

  function isLoading() {
    if (loading || !loggedInUser) return true;
    return false;
  }

  return (
    <Container>
      <Inputs>
        <MaterialInput
          fontSize={"19px"}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          multiline={true}
          label={"Title"}
        />

        {descriptionStatus ? (
          <MaterialInput
            rows={3}
            multiline={true}
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        ) : null}
      </Inputs>

      {getWarning()}

      {getSettingsSection()}

      <Row>
        <Left>
          <FilledButton
            onClick={enableDisableDesc}
            icon={descriptionStatus ? <AiOutlineMinus /> : <AiOutlinePlus />}
          >
            Description
          </FilledButton>

          <DropDownInput
            label="Category"
            options={contentCategories[type]}
            value={category}
            onChange={setCategory}
          />

          <FilledButton
            icon={<AiOutlineSetting />}
            onClick={enableDisableSettings}
          />
        </Left>

        <FilledButton
          onClick={create}
          icon={<AiOutlineSend />}
          isLoading={isLoading()}
        ></FilledButton>
      </Row>
    </Container>
  );

  function getSettingsSection() {
    if (!showSettings) return null;

    return (
      <Settings>
        <MaterialInput
          type="number"
          value={maxMembersPerRoom}
          onChange={(e) => {
            setmaxMembersPerRoom(e.target.value);
          }}
          multiline={true}
          label={"Maximum Members Per Room"}
        />

        <MaterialInput
          type="number"
          value={maxRoomsAutoAddAuthorTo}
          onChange={(e) => {
            setMaxRoomsAutoAddAuthorTo(e.target.value);
          }}
          multiline={true}
          label={"Max rooms author should be auto added to?"}
        />
      </Settings>
    );
  }

  function getWarning() {
    if (title.length > maxTitleLength)
      return (
        <Warning>
          Please remove {title.length - maxTitleLength} character from the title
        </Warning>
      );

    if (description)
      if (description.length > maxDescriptionLength)
        return (
          <Warning>
            Please remove {description.length - maxDescriptionLength} character
            from the description
          </Warning>
        );
  }

  function enableDisableSettings() {
    setShowSettings(!showSettings);
  }

  function enableDisableDesc() {
    if (descriptionStatus) {
      setDescription("");
    }

    setDescriptionStatus(!descriptionStatus);
  }

  function create() {
    if (isLoading()) return false;

    if (!category) return popupAlert("Please select a category");

    if (parseInt(maxMembersPerRoom) <= 2)
      return popupAlert("Maximum member per room should be greater than 10");

    if (parseInt(maxRoomsAutoAddAuthorTo) <= 2)
      return popupAlert("Author needs to be added to at lest 3 rooms");

    if (!category) return popupAlert("Please select a category");

    if (title.length > 50)
      return popupAlert("Title length should be less than 50 characters");
    if (description) {
      if (description.length > 500)
        return popupAlert(
          "Description length should be less than 500 characters"
        );
    }

    setLoading(true);

    if (contentToUpdate) {
      serverLine
        .patch("/content", {
          contentID: contentToUpdate._id,
          changes: {
            category: category,
            title: title,
            description: { text: description },
            maxRoomsAutoAddAuthorTo,
            maxMembersPerRoom,
          },
        })
        .then((newData) => {
          setLoading(false);
          goTo("/content/" + newData._id)();
        });
    } else {
      try {
        serverLine
          .post("/content", {
            values: {
              category: category,
              title: title,
              description: { text: description },
              maxRoomsAutoAddAuthorTo,
              maxMembersPerRoom,
            },
          })
          .then((newData) => {
            setLoading(false);
            goTo("/content/" + newData._id)();
          });
      } catch (e) {
        popupAlert(e.message);
        setLoading(false);
      }
    }
  }
}
