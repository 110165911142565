import styled from "styled-components";
import CustomButton from "./inputs/CustomButton";

const Container = styled.div`
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  /* border-top: 1px solid var(--translucentHard); */
  margin-top: 0;
  background-color: var(--secondaryInput);
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
`;

const Info = styled.div``;

export default function ReportsDataBox({ item, subjectType }) {
  return (
    <Container>
      <Info>New Reports:{item.newReportsCount}</Info>

      <Info>
        Old Reports:
        {item.oldReportsCount}
      </Info>

      <CustomButton
        style={{ borderRadius: "10px" }}
        variant="minimal-underlined"
        href={`/item-reports/?subjectID=${
          item._id
        }&subjectType=${subjectType.toLowerCase()}`}
      >
        Show All Reports
      </CustomButton>
    </Container>
  );
}
