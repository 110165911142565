import reachChatPanelEnd from "../../../../../util/controllers/reachChatPanelEnd";
import addGroupToChatGroupPanel from "./addGroupToChatGroupPanel";
import addMessageToTheUI from "./addMessageToTheUI";

export default function messageTransactionHappened({
  sentOrReceived,
  messageDoc,
  chatGroup,
  sender,
  oppositeMember,
  membershipData,
}) {
  const { selectedChatGroupData } = window.globalChatSpace;

  let chatGroupID = chatGroup._id;

  let messageIsForSelectedGroup = false;

  if (selectedChatGroupData) {
    if (selectedChatGroupData.group._id == chatGroupID) {
      messageIsForSelectedGroup = true;

      reachChatPanelEnd();
      addMessageToTheUI({
        messageDoc,
        sender,
        chatGroupID,
        sentOrReceived,
      });
    }
  }

  if (!messageIsForSelectedGroup) {
    console.log("Message was not for messageIsForSelectedGroup");
    let theOppositeMember =
      sentOrReceived == "RECEIVED" ? sender : oppositeMember;
    addGroupToChatGroupPanel({
      messageDoc,
      membershipData,
      chatGroup,
      oppositeMember: theOppositeMember,
      itsAnUnreadMessage: sentOrReceived == "RECEIVED",
    });
  }
}
