import { useContext } from "react";
import styled from "styled-components";
import { GrGoogle } from "react-icons/gr";
import {
  RiChat3Fill,
  RiChat3Line,
  RiHome2Fill,
  RiHome2Line,
  RiHomeLine,
  RiNotification2Line,
  RiSearch2Fill,
  RiSearch2Line,
  RiUserFill,
  RiUserLine,
} from "react-icons/ri";
import goToProfile from "../../controllers/goToProfile.js";
import Context from "../../../Context.js";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import goTo from "../../controllers/goTo.js";

import getProfileLink from "../../controllers/getProfileLink.js";
import BrandContainer from "../brand/BrandContainer.js";
import { FiAlertTriangle } from "react-icons/fi";

import { PiFeatherFill, PiFeatherLight } from "react-icons/pi";
import getUnreadMessageCount from "../../../pages/chatPage/controllers/ui/getUnreadMessageCount.js";
import SemanticButton from "../SemanticButton.js";
import CustomButton from "../inputs/CustomButton.js";
import { AiOutlinePlus } from "react-icons/ai";
import FilledButton from "../buttons/FilledButton.js";
import MobileHeader from "./MobileHeader.js";
import AccountSwitcher from "../profile/AccountSwitcher.js";
import calculateUnreadMessagesCount from "../../../pages/chatPage/controllers/ui/calculateUnreadMessagesCount.js";
import { HiOutlineMenuAlt4 } from "react-icons/hi";

const Container = styled.div`
  display: flex;
  position: sticky;
  top: var(--mainPadding);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: var(--primarySurface);
  //this highlights the logo more
  width: var(--headerWidth);
  height: calc(100vh - var(--mainPadding) - var(--mainPadding));
  font-family: "Raleway";
  overflow: hidden;
  align-items: center;
  padding: 0;
  margin: var(--mainPadding);

  /* margin-right: 0; // Padding on the content takes care of it */
  //0 margin on the right also makes the chat page appear more equally spaced
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: center;

  gap: 15px;
  align-items: center;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  padding: 13px 0;
  /* padding-left: 80px; */
  font-size: 19px;
  color: rgb(0 110 171);
  width: 80%;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  color: var(--inputText);
  background: var(--secondaryInput);
  /* padding-left: 30px; */

  ${({ highlight }) => {
    if (highlight)
      return `
        background: var(--primaryInput);
    `;
  }}

  &:hover {
    transform: scale(0.95);
    background: var(--primaryInput);
  }

  @media (max-width: 900px) {
    background: transparent;
    border: none;
    width: auto;
    padding: 10px 10px;
    opacity: 0.6;

    ${({ highlight }) => {
      if (highlight)
        return `
        opacity: 1;
        width:140px;
        padding: 10px 10px;
        color:  var(--foreground);
        background: #3c94dbd4;
    `;
    }}
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  align-items: center;
`;

const InputText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 60px;
  font-size: 14px;
  text-align: left;
  /* font-family: "Montserrat"; */
  /* background-color: red; */
  justify-content: flex-start;
  opacity: 0.8;
  position: relative;

  @media (max-width: 900px) {
    width: auto;
  }
`;

const Items = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  padding-bottom: 30px;
  align-items: center;

  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: row;
    padding: 0 10px;
    gap: 0;
    width: auto;
    justify-content: space-between;
    /* width: 100%; */
  }
`;

const NotificationBubble = styled.div`
  display: flex;
  position: absolute;
  right: -50px;
  /* top: 15px; */
  padding: 0;
  border-radius: 500px;
  height: 25px;
  padding: 0 10px;
  width: auto;
  font-size: 12px;
  background-color: var(--accentColor);
  color: var(--primarySurface);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  @media (max-width: 900px) {
    right: -10px;
    top: -10px;
    font-size: 10px;
    padding: 0 7px;
    height: 20px;
    font-weight: 900;
  }
`;

const NotificationBubbleAlert = styled(NotificationBubble)`
  /* color: tomato; */
  background-color: tomato;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 35px 0;
  align-items: center;

  width: 100%;

  background: var(--primarySurface);
  border-bottom: 1px solid var(--foreground);

  @media (max-width: 900px) {
    background: transparent;
    border: none;
    width: auto;
  }
`;

const IconRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function Header() {
  const { loggedInUser, currentRoute, notificationData, isMobile } =
    useContext(Context);

  let unreadMessages = calculateUnreadMessagesCount();

  let logoComp = <BrandContainer />;

  if (isMobile) return <MobileHeader />;

  if (!loggedInUser)
    return (
      <Container>
        <Logo>{logoComp}</Logo>

        <Items>
          <FilledButton
            style={{ width: "90%", borderRadius: "10px" }}
            variant="filled"
            onClick={goToAuthScreen}
            icon={<GrGoogle />}
          >
            Login / Signup
          </FilledButton>
        </Items>
      </Container>
    );

  return (
    <Container>
      <Logo>{logoComp}</Logo>
      <Items>
        <ProperButton
          link={"/"}
          icon={<RiHomeLine />}
          highlight={checkHighlight("/")}
        >
          Home
        </ProperButton>
        <ProperButton
          link={`/notifications`}
          icon={<RiNotification2Line />}
          highlight={checkHighlight("/notifications")}
        >
          Notifs
          {notificationData.general ? (
            <NotificationBubble>{notificationData.general}</NotificationBubble>
          ) : null}
        </ProperButton>
        <ProperButton
          onClick={openChatPage}
          link={"/chat"}
          icon={<RiChat3Line />}
          highlight={checkHighlight("/chat")}
        >
          Chat
          {unreadMessages ? (
            <NotificationBubble>{unreadMessages}</NotificationBubble>
          ) : null}
        </ProperButton>

        <ProperButton
          highlight={checkHighlight(getProfileLink(loggedInUser.username))}
          link={getProfileLink(loggedInUser.username)}
          onClick={goToProfile(loggedInUser.username)}
          icon={<RiUserLine />}
        >
          Profile
        </ProperButton>
        <IconRow>
          <ProperButton
            style={{ width: "auto", flex: 1 }}
            link={`/more-menu`}
            icon={<HiOutlineMenuAlt4 />}
            highlight={checkHighlight("/more-menu")}
          ></ProperButton>
          <ProperButton
            link={`/search`}
            style={{ width: "auto", flex: 1 }}
            icon={<RiSearch2Line />}
            highlight={checkHighlight("/search")}
          ></ProperButton>

          <ProperButton
            style={{ width: "auto", flex: 1 }}
            link={`/create`}
            icon={<AiOutlinePlus />}
            highlight={checkHighlight("/create")}
          ></ProperButton>
        </IconRow>
        <AccountSwitcher />
      </Items>
    </Container>
  );

  function openChatPage() {
    goTo("/chat")();
  }

  function checkHighlight(route) {
    if (route !== "/") {
      return currentRoute.indexOf(route) !== -1;
    }
    return route == currentRoute;
  }
}

function ProperButton({
  link,
  onClick,
  icon,
  style,
  children,
  highlight,
  bubble,
}) {
  const { isMobile } = useContext(Context);

  return (
    <SemanticButton
      style={{ width: isMobile ? "auto" : "100%" }}
      semanticHref={link}
      onClick={onClick ? onClick : goTo(link)}
    >
      <Button style={style} highlight={highlight}>
        <ButtonIcon>{icon}</ButtonIcon>
        {children ? <InputText>{children}</InputText> : null}
        {bubble}
      </Button>
    </SemanticButton>
  );
}
