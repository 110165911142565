import { serverLine } from "../../../../../util/controllers/serverLine";
import reactionTransactionHappened from "../message/reactionTransactionHappened";

export default async function sendReaction({ reaction, messageID, chatGroup }) {
  let chatGroupID = chatGroup._id;
  let { loggedInUser } = window.globalChatSpace;

  reactionTransactionHappened({
    sender: loggedInUser,
    reaction,
    messageID,
    chatGroup: chatGroup,
  });

  await serverLine.post("/reaction", {
    reaction,
    messageID,
    chatGroupID,
  });

  //We send message after saving because of two reasons
  //Enforce blocking
  //Avoid the case where reaction is being saved but the original post is not yet saved
  window.chatSocket.emit("reaction", {
    reaction,
    messageID,
    chatGroupID,
  });
}
