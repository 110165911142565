import { useEffect } from "react";
import initializeChat from "../../controllers/socket/initializeChat";

export default function SetupChat() {
  useEffect(() => {
    return initializeChat();
  }, []);

  return null;
}
