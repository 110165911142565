import { styled } from "styled-components";
import { serverLine } from "../../util/controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import getSubPath from "../../util/controllers/getSubPath";
import RenderContent from "./RenderContent";
import LoadingSection from "../../util/components/LoadingSection";
import Context from "../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 62vw;
  background: var(--primarySurface);
  min-height: calc(100vh - var(--mainPadding) - var(--mainPadding));
  border-radius: 10px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

export default function ContentPage() {
  const { currentRoute } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    let contentID = getSubPath(1, currentRoute);
    serverLine.get("/content/?contentID=" + contentID).then((newData) => {
      setPostData(newData);
      setLoading(false);
    });
  }, [currentRoute]);

  if (!postData || loading) return <LoadingSection />;

  return (
    <Container>
      <Main>
        <RenderContent setPostData={setPostData} postData={postData} />
        <br /> <br /> <br /> <br /> <br /> <br />
      </Main>
    </Container>
  );
}
