import styled from "styled-components";
import getImageURL from "../../../../util/controllers/getImageURL";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import { useState } from "react";
import { serverLine } from "../../../../util/controllers/serverLine";
import sendVotedOut from "../../controllers/socket/send/sendKickOut";

const Container = styled.div`
  background: var(--secondaryInput);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Main = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const ProfileImage = styled.img`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 10px;
`;
const Name = styled.div`
  font-size: 18px;
  font-weight: 900;
`;
const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 15px;
`;

const InfoText = styled.div``;

export default function ChatMemberBox({ userData, voteData, membershipData }) {
  const [voteOutStatus, setVoteOutStatus] = useState(voteData);
  const [removed, setRemoved] = useState(false);
  const [increment, setIncrement] = useState(0);

  if (removed) return null;
  return (
    <Container>
      <Main>
        <ProfileImage src={getImageURL(userData.profileImage, true)} />
        <MainInfo>
          <Name>{userData.name}</Name>
          <SecondaryInfo>
            <InfoText>{userData.username}</InfoText>
            <InfoText>.</InfoText>
            <InfoText>
              Vote Outs: {membershipData.voteOuts + increment}
            </InfoText>
          </SecondaryInfo>
        </MainInfo>
      </Main>

      <Buttons>
        <FilledButton
          href={"/" + userData.username}
          colorVariation={"SURFACE"}
          onClick={voteOut}
        >
          Profile
        </FilledButton>
        {getButton()}
      </Buttons>
    </Container>
  );

  function getButton() {
    if (voteOutStatus)
      return (
        <FilledButton colorVariation={"SURFACE"} onClick={voteOut}>
          Cancel Vote Out
        </FilledButton>
      );

    return (
      <FilledButton colorVariation={"SURFACE"} onClick={voteOut}>
        Vote Out
      </FilledButton>
    );
  }

  async function voteOut() {
    let { loggedInUserID } = window.globalChatSpace;

    if (userData._id == loggedInUserID)
      return window.popupAlert("You can't vote out yourself");
    if (voteOutStatus) {
      setIncrement(increment - 1);
    } else {
      setIncrement(increment + 1);
    }

    let { selectedChatGroupID } = window.globalChatSpace;
    setVoteOutStatus(voteOutStatus ? false : true);
    let data = await serverLine.post("/vote-out-chat-member", {
      receiverUserID: userData._id,
      chatGroupID: selectedChatGroupID,
    });

    if (data) {
      window.popupAlert("The member has been removed");
      sendVotedOut({ userID: userData._id, chatGroupID: selectedChatGroupID });
      setRemoved(true);
    }
  }
}
