import { styled } from "styled-components";
import { AiOutlineSend } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";
import MessageBox from "./messageBox/MessageBox";
import Context from "../../../../Context";
import { nanoid } from "nanoid";
import { GoImage } from "react-icons/go";
import { BsChevronLeft, BsThreeDotsVertical } from "react-icons/bs";
import OnlineBubble from "../common/OnlineBubble";
import sendTypingData from "../../controllers/socket/send/sendTypingData";
import readTypingData from "../../controllers/ui/readTypingData";
import getChatUiData from "../../controllers/ui/getChatUiData";
import getOppositeMemberID from "../../../../util/controllers/getOppositeMemberID";
import loadSelectedChatGroup from "../../controllers/api/loadSelectedChatGroup";
import sendMessage from "../../controllers/socket/send/sendMessage";
import leaveChatGroup from "../../controllers/api/leaveChatGroup";
import BlockUnblockInterface from "../../../profilePage/BlockUnblockInterface";
import handleSendingIfBlocked from "../../../../util/controllers/utils/handleSendingIfBlocked";
import limitStringLength from "../../../../util/controllers/limitStringLength";
import getOnlineOfflineStatus from "../../../../util/controllers/socket/getOnlineOfflineStatus";
import SemanticButton from "../../../../util/components/SemanticButton";
import getProfileLink from "../../../../util/controllers/getProfileLink";
import goToProfile from "../../../../util/controllers/goToProfile";
import LoadingSection from "../../../../util/components/LoadingSection";
import CustomButton from "../../../../util/components/inputs/CustomButton";
import MaterialInput from "../../../../util/components/MaterialInput";
import goTo from "../../../../util/controllers/goTo";
import compressAndUploadFile from "../../../../util/controllers/compressAndUploadFile";
import selectFile from "../../../../util/controllers/selectFile";
import reachChatPanelEnd from "../../../../util/controllers/reachChatPanelEnd";
import getImageURL from "../../../../util/controllers/getImageURL";
import usePopupDialog from "../../../../util/lib/popupDialog/usePopupDialog";
import Options from "../../../../util/components/inputs/Options";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import { height } from "@mui/system";
import ReplyingToInfo from "./ReplyingToInfo";
import ObjectID from "bson-objectid";
import getObjectID from "../../../../util/controllers/getObjectID";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  height: calc(100% - 175px);
  overflow: hidden;
`;

const Messages = styled.div`
  display: flex;
  flex: 1;

  /* height: calc(100% - 195px); // 200 subtracted for bottom bar */
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px;
`;

const Notice = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 27px;
  opacity: 0.7;
  font-size: 900;
  justify-content: center;
  align-items: center;
`;

export default function ChatMessagesPanel() {
  const { selectedChatGroupID, selectedChatGroupData } = window.globalChatSpace;

  useEffect(() => {
    console.log("Scrolling to the end");
    reachChatPanelEnd();
  }, []); //ChatGroupBox was removed because it was scroll to the bottom on reactions as well

  let { selectedChatGroupIsLoading } = window.globalChatSpace;

  if (selectedChatGroupIsLoading)
    return (
      <Container selectedChatGroupID={selectedChatGroupID}>
        <LoadingSection />
      </Container>
    );

  if (!selectedChatGroupData)
    return (
      <Container selectedChatGroupID={selectedChatGroupID}>
        <Notice> Please select a chat to start messaging </Notice>
      </Container>
    );

  let group = selectedChatGroupData.group;

  let messages = selectedChatGroupData.messages;

  return (
    <Messages id="chat-panel">
      {messages.map((item) => (
        <MessageBox key={item._id} group={group} message={item} />
      ))}
    </Messages>
  );
}
