import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";

import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";

import FilledButton from "../buttons/FilledButton";
import openLoginPopup from "../../controllers/openLoginPopup";
import { serverLine } from "../../controllers/serverLine";
import Context from "../../../Context";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Info = styled.div``;

export default function LikeDislikeButtons({
  likeStatus,
  likeCount = 0,
  dislikeCount = 0,
  contentID,
  commentID,
  conclusionID,
  authorUserID,
  colorVariation,
}) {
  const { loggedInUserID } = useContext(Context);
  const [status, setStatus] = useState(likeStatus ? likeStatus : "NEUTRAL");
  const [likeCountState, setLikeCountState] = useState(0);
  const [dislikeCountState, setDislikeCountState] = useState(0);

  useEffect(() => {
    if (typeof likeCount == "number") {
      setLikeCountState(likeCount);
    }

    if (typeof dislikeCount == "number") {
      setDislikeCountState(dislikeCount);
    }
  }, []);

  let details = { contentID, commentID, conclusionID };

  let buttons = [];

  return (
    <Buttons>
      <FilledButton
        colorVariation={colorVariation}
        onClick={like}
        icon={status === "LIKE" ? <AiFillLike /> : <AiOutlineLike />}
      >
        {likeCountState.toString()}
      </FilledButton>

      <FilledButton
        colorVariation={colorVariation}
        onClick={dislike}
        icon={status === "DISLIKE" ? <AiFillDislike /> : <AiOutlineDislike />}
      >
        {dislikeCountState.toString()}
      </FilledButton>

      {buttons}
    </Buttons>
  );

  function like() {
    if (!loggedInUserID) return openLoginPopup();
    if (authorUserID == loggedInUserID)
      return window.popupAlert("You can't like your own content");

    serverLine.post("/like-dislike", { ...details, type: "LIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      toIncrementLikeCount++;
      setStatus("LIKE");
    } else if (status === "LIKE") {
      toIncrementLikeCount--;
      setStatus("NEUTRAL");
    } else if (status === "NEUTRAL") {
      toIncrementLikeCount++;
      setStatus("LIKE");
    }

    setLikeCountState(likeCountState + toIncrementLikeCount);
    setDislikeCountState(dislikeCountState + toIncrementDislikeCount);
  }

  function dislike() {
    if (!loggedInUserID) return openLoginPopup();
    if (authorUserID == loggedInUserID)
      return window.popupAlert("You can't like your own content");
    serverLine.post("/like-dislike", { ...details, type: "DISLIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      setStatus("NEUTRAL");
    } else if (status === "LIKE") {
      toIncrementLikeCount--;
      toIncrementDislikeCount++;
      setStatus("DISLIKE");
    } else if (status === "NEUTRAL") {
      toIncrementDislikeCount++;
      setStatus("DISLIKE");
    }

    setLikeCountState(likeCountState + toIncrementLikeCount);
    setDislikeCountState(dislikeCountState + toIncrementDislikeCount);
  }
}
