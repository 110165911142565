import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;

  width: 100%;

  background: var(--primarySurface);
  border: 1px solid var(--foreground);
  border-radius: 10px;

  cursor: pointer;
`;
const Title = styled.div`
  white-space: nowrap;
  font-size: 24px;
  font-weight: 900;
  max-width: 30vw;
  overflow: hidden;
`;

const Subtitle = styled.div``;

export default function ReportBox({ item }) {
  let { _id, title } = item;

  return (
    <Container>
      <Title>{item.reason}</Title>
      <Subtitle>{item.description}</Subtitle>
    </Container>
  );
}
