export default function getOppositeMemberID({ chatGroup, loggedInUserID }) {
  if (chatGroup.type !== "DIRECT_CHAT") return null;
  let ids = getMemberIDsOfDC(chatGroup.uniqueChatGroupAddress);
  let oppositeMemberID = getTheID({ ids, loggedInUserID });
  return oppositeMemberID;
}

function getMemberIDsOfDC(address) {
  address = address.replace("dc-", "");
  return address.split("-");
}

function getTheID({ ids, loggedInUserID }) {
  if (ids[0] === loggedInUserID) return ids[1];
  return ids[0];
}
