export default function addReactionToUI({ messageID, reaction, sender }) {
  const { selectedChatGroupData, setSelectedChatGroupData, loggedInUserID } =
    window.globalChatSpace;

  let oldSelectedGrpData = { ...selectedChatGroupData };

  for (let item of oldSelectedGrpData.messages) {
    if (item._id == messageID) {
      if (!item.reactions) item.reactions = {};

      item.reactions[loggedInUserID] = { value: reaction, author: sender };
    }
  }

  setSelectedChatGroupData(oldSelectedGrpData);
}
