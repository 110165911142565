import { ThemeProvider, createTheme } from "@mui/material/styles/index.js";
import Boilerplate from "./util/core/boilerplate/Boilerplate";
import CustomRouter from "./util/lib/CustomRouter";
import PopupDialogProvider from "./util/lib/popupDialog/PopupDialogProvider";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#e8eaf6",
    },
    secondary: {
      main: "#8bc34a",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Boilerplate>
        <CustomRouter />
      </Boilerplate>
    </ThemeProvider>
  );
}

export default App;
