import messageTransactionHappened from "../message/messageTransactionHappened";

export default function onMessage(message) {
  // window.popupAlert("Message received");

  const { chatGroupsAreLoading, selectedChatGroupIsLoading } =
    window.globalChatSpace;
  if (selectedChatGroupIsLoading || chatGroupsAreLoading) return;

  let messageDoc = message.messageDoc;
  let chatGroup = message.chatGroup;
  let sender = message.sender;
  let membershipData = message.membershipData;

  messageTransactionHappened({
    membershipData,
    sentOrReceived: "RECEIVED",
    messageDoc,
    chatGroup,
    sender,
  });
}
