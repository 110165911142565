import styled from "styled-components";
import goToAuthScreen from "../../../util/controllers/goToAuthScreen.js";
import { HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext } from "react";
import Context from "../../../Context.js";
import CustomButton from "../../../util/components/inputs/CustomButton.js";
import BrandContainer from "../../../util/components/brand/BrandContainer.js";
import ContentFeed from "./feed/ContentFeed.js";
import MainFeed from "./feed/MainFeed.js";
import FilledButton from "../../../util/components/buttons/FilledButton.js";
import usePopupDialog from "../../../util/lib/popupDialog/usePopupDialog.js";
import Options from "../../../util/components/inputs/Options.js";
import LoadingSection from "../../../util/components/LoadingSection.js";

const ParentContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background: red; */
  padding: 0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 60px;

  background: var(--primarySurface);

  gap: 120px;
  border-radius: 10px;
  width: 100%;
  padding-bottom: 0;
  /* border-radius: 0 20px 20px 0; */

  font-weight: 700;
  font-family: "Raleway", sans-serif;
  /* width: 100vw; */
  box-shadow: 0px 5px 0px 1px var(--accentLightColor);

  @media (max-width: 1300px) {
    gap: 0;
    justify-content: space-between;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 20px 10px;
    gap: 0;
    justify-content: space-between;
    padding-top: 60px;
  }
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Staatliches", sans-serif;

  @media (max-width: 900px) {
    font-size: 30px;
    width: 90%;
  }
`;
const MainButtons = styled.div`
  /* margin-top: 50px; */
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 60px;
  align-items: center;
  border: none;
  justify-content: center;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
    justify-content: space-between;
    /* justify-content: center; */
    /* flex-direction: column; */
  }
`;

const Medium = styled.div`
  font-size: 16px;
  opacity: 0.8;
  font-weight: 600;
  text-align: center;
  opacity: 0.5;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    width: 90%;
    font-size: 15px;
    line-height: 23px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  /* margin-top: 90px; */
  width: 45vw;
`;

const BottomDecoration = styled.img`
  margin-bottom: -10px;
`;

const btnStyle = {
  padding: "15px 30px",
  background: "transparent",
  color: "var(--accentColor)",
  border: "1px solid var(--accentColor)",
  boxShadow: "0px 3px 0px 1px var(--accentColor)",
};

let moreButtonStyle = {
  ...btnStyle,
  padding: "15px 20px",
};

export default function LoggedOutHomePage() {
  const { closePopup, showPopup } = usePopupDialog();

  return (
    <ParentContainer>
      <Main>
        <BrandContainer />
        <TextContainer>
          <Tagline>Discuss, Debate & Make New Friends</Tagline>
          <Medium>
            When you write an opinion/idea/question, it becomes a chat group
            which can have multiple rooms, each room can have maximum of 5
            people. You can discuss and refine your opinion in the room, rooms
            are completely private, only the members of the room can view the
            messages, at the end each member has to come up with a conclusion,
            which is made public, which is then voted to filter out the best
            conclusion.
          </Medium>
          <br />

          <MainButtons>
            <FilledButton
              textStyle={{ fontWeight: "700" }}
              style={btnStyle}
              icon={<GrGoogle />}
              onClick={goToAuthScreen}
            >
              Login With Google
            </FilledButton>
            <FilledButton
              style={moreButtonStyle}
              onClick={openMoreMenu}
              icon={<HiMenuAlt4 />}
            ></FilledButton>
          </MainButtons>
        </TextContainer>

        <BottomDecoration src="/decorations/bottom-decoration.png" />
      </Main>
      <br /> <br /> <br />
      <MainFeed />
    </ParentContainer>
  );

  function open(link) {
    return () => {
      return (window.location = link);
    };
  }

  function openMoreMenu() {
    let options = [
      {
        label: "About us",
        onClick: open("https://arnav.foreverkite.com"),
      },
    ];

    showPopup({
      title: "Options",
      component: <Options options={options} />,
    });
  }
}
