import addReactionToUI from "./addReactionToUI";

export default function reactionTransactionHappened({
  messageID,
  reaction,
  chatGroup,
  sender,
}) {
  const { selectedChatGroupData } = window.globalChatSpace;

  let chatGroupID = chatGroup._id;

  if (selectedChatGroupData) {
    if (selectedChatGroupData.group._id == chatGroupID) {
      addReactionToUI({
        messageID,
        reaction,
        sender,
      });
    }
  }
}
