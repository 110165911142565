import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../../../Context";
import { BsChevronLeft, BsThreeDotsVertical } from "react-icons/bs";
import OnlineBubble from "../common/OnlineBubble";

import readTypingData from "../../controllers/ui/readTypingData";
import getChatUiData from "../../controllers/ui/getChatUiData";
import getOppositeMemberID from "../../../../util/controllers/getOppositeMemberID";
import loadSelectedChatGroup from "../../controllers/api/loadSelectedChatGroup";

import leaveChatGroup from "../../controllers/api/leaveChatGroup";
import BlockUnblockInterface from "../../../profilePage/BlockUnblockInterface";

import limitStringLength from "../../../../util/controllers/limitStringLength";
import getOnlineOfflineStatus from "../../../../util/controllers/socket/getOnlineOfflineStatus";
import SemanticButton from "../../../../util/components/SemanticButton";
import getProfileLink from "../../../../util/controllers/getProfileLink";
import goToProfile from "../../../../util/controllers/goToProfile";
import CustomButton from "../../../../util/components/inputs/CustomButton";

import goTo from "../../../../util/controllers/goTo";
import reachChatPanelEnd from "../../../../util/controllers/reachChatPanelEnd";
import getImageURL from "../../../../util/controllers/getImageURL";
import usePopupDialog from "../../../../util/lib/popupDialog/usePopupDialog";
import Options from "../../../../util/components/inputs/Options";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import DropDownLocationInput from "../../../../util/components/DropDownLocationInput";
import DropDownInput from "../../../../util/components/inputs/DropDownInput";
import muteSelectedChatGroup from "../../controllers/api/muteSelectedChatGroup";
import getChatGroupMuteStatus from "../../controllers/ui/getChatGroupMuteStatus";

const TopPart = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid var(--secondaryInput);
  width: 100%;

  @media (max-width: 900px) {
    gap: 10px;
    padding: 15px;
  }
`;

const TopProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const TopProfileLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  transition: all 0.15s ease-in;
`;

const Image = styled.img`
  height: 47px;
  width: 47px;
  object-fit: cover;
  border-radius: 50px;
`;
const Name = styled.div`
  font-weight: 800;
  font-size: 15px;
  text-transform: capitalize;
  /* white-space: nowrap; */
  width: 100%;
  overflow: hidden;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.7;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
`;

const Username = styled.div`
  &:hover {
    text-decoration: underline;
    /* transform: scale(0.9); */
  }
`;

const BackButton = styled.div`
  opacity: 0.5;
  display: none;
  @media (min-width: 900px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const HeadingInfo = styled.div``;

const rightPanelOptions = [
  {
    label: "Messages",
    value: "MESSAGES",
  },
  {
    label: "Conclusions",
    value: "CONCLUSIONS",
  },
  {
    label: "Members",
    value: "MEMBERS",
  },
  {
    label: "Options",
    value: "OPTIONS",
  },
];

export default function ChatMessagesPanelHeader({ tab, setTab }) {
  const { isMobile } = useContext(Context);
  const { loggedInUserID } = useContext(Context);

  const { selectedChatGroupData, friendsLastSeenAt } = window.globalChatSpace;

  let { title, image, username } = getChatUiData(
    selectedChatGroupData.group,
    selectedChatGroupData.oppositeMember
  );

  let imageComp = null;

  if (selectedChatGroupData.group.type == "DIRECT_CHAT") {
    let oppositeMemberID = getOppositeMemberID({
      chatGroup: selectedChatGroupData.group,
      loggedInUserID,
    });
    let onlineStatus = getOnlineOfflineStatus({
      friendsLastSeenAt,
      oppositeMemberID: oppositeMemberID,
    });

    imageComp = (
      <ImageContainer>
        <Image src={getImageURL(image, true)} />
        {onlineStatus ? <OnlineBubble /> : null}
      </ImageContainer>
    );
  }

  return (
    <TopPart>
      <BackButton>
        <FilledButton onClick={goTo(-1)} icon={<BsChevronLeft />} />
      </BackButton>

      <TopProfile>
        <TopProfileLeft>
          {imageComp}
          <TextSection>
            <Name>{limitStringLength(title, isMobile ? 20 : 50)}</Name>
            <SubHeading>
              {getTabSwitcher()}
              {getSubHeading()}
              {getTypingStatus()}
            </SubHeading>
          </TextSection>
        </TopProfileLeft>
      </TopProfile>
    </TopPart>
  );

  function getTabSwitcher() {
    if (!isMobile) return null;
    return (
      <DropDownInput
        value={tab}
        onChange={setTab}
        options={rightPanelOptions}
      />
    );
  }

  function getSubHeading() {
    if (selectedChatGroupData.group.type == "CONTENT_CHAT") {
      let memberCount = selectedChatGroupData.membersMembershipData.length;

      return <HeadingInfo>{memberCount} Members</HeadingInfo>;
    }

    if (!username) return null;
    return (
      <SemanticButton
        semanticHref={getProfileLink(username)}
        onClick={goToProfile(username)}
      >
        <Username> @{username}</Username>
      </SemanticButton>
    );
  }

  function getTypingStatus() {
    return <HeadingInfo>{readTypingData()}</HeadingInfo>;
  }
}
