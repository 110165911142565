import { useContext, useState } from "react";
import styled from "styled-components";
import Context from "../../../Context";
import limitStringLength from "../../controllers/limitStringLength";
import { serverLine } from "../../controllers/serverLine";
import CustomToggle from "../inputs/CustomToggle";
import LoadingSection from "../LoadingSection";
import FilledButton from "../buttons/FilledButton";

const Container = styled.div`
  padding: 10px;
  background: var(--secondaryInput);
`;

function AccountSwitcher() {
  let { allProfiles, loggedInUser } = useContext(Context);
  let [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  let options = allProfiles.map((item) => {
    return {
      value: item._id,
      label: limitStringLength(item.name.split(" ")[0], 7),
    };
  });

  if (loading)
    return <FilledButton style={{ width: "80%" }}>Please Wait...</FilledButton>;

  return (
    <CustomToggle
      btnStyle={{ flex: 1, borderRadius: "10px" }}
      value={loggedInUser._id}
      options={options}
      onChange={changeProfile}
      toggleContainerStyle={{ width: "100%" }}
      toggleStyle={{ width: "80%", padding: "0" }}
    />
  );

  function done(data) {
    if (data) {
      localStorage.setItem("cottonchat-token", data.token);
      localStorage.setItem("cottonchat-userID", data.userID);
      window.location = "/";
    }
  }

  function changeProfile(newID) {
    setLoading(true);
    serverLine
      .post("/switch-profile", { id: newID })
      .then(done)
      .catch(({ message }) => {
        if (typeof message == "object") message = JSON.stringify(message);
        window.popupAlert(message);
        setError(message);
      });
  }
}

export default AccountSwitcher;
