import reactionTransactionHappened from "../message/reactionTransactionHappened";

export default function onReaction(message) {
  const { chatGroupsAreLoading, selectedChatGroupIsLoading } =
    window.globalChatSpace;

  if (selectedChatGroupIsLoading || chatGroupsAreLoading) return;

  let messageID = message.messageID;
  let chatGroup = message.chatGroup;
  let sender = message.sender;
  let reaction = message.reaction;

  reactionTransactionHappened({
    sentOrReceived: "RECEIVED",
    messageID,
    reaction,
    chatGroup,
    sender,
  });
}
