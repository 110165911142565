import { TextField } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function MaterialInput({
  value,
  onChange,
  label,
  disableUnderline,
  fontSize,
  multiline,
  maxRows,
  onEnter,
  fontWeight,
  rows,
  type,
  autoFocus = false,
  onlyYear,
  variant = "filled",
  placeholder,
  hideBorder,
}) {
  let inputProps = {
    disableUnderline: disableUnderline ? true : false,

    style: {
      background: "var(--secondaryInput)",
      border: "none",
      fontSize: fontSize,
      fontWeight: fontWeight,
      borderRadius: "10px",
      color: "var(--color)",
    },
  };

  let sx = {
    input: { color: "var(--color)", fontWeight: 500 },
    textarea: { color: "var(--color)", fontWeight: 500 },
  };

  let InputLabelProps = {
    style: { fontSize: fontSize, color: "var(--color)" },
  };

  if (type == "date")
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          views={onlyYear ? ["year"] : null}
          onChange={(newDate) => {
            let theVal = newDate["$d"];
            if (theVal.toString() === "Invalid Date") return true;
            console.log(newDate["$d"].toISOString());
            onChange({ target: { value: newDate["$d"].toISOString() } });
          }}
          value={value ? dayjs(value) : null}
          variant={variant}
          InputProps={inputProps}
          InputLabelProps={InputLabelProps}
          sx={sx}
        />
      </LocalizationProvider>
    );

  return (
    <TextField
      shrink={true}
      autoFocus={autoFocus}
      type={type}
      InputLabelProps={InputLabelProps}
      InputProps={inputProps}
      sx={sx}
      fullWidth
      multiline={multiline}
      maxRows={maxRows}
      placeholder={placeholder}
      onKeyDown={(ev) => {
        console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === "Enter") {
          if (onEnter) {
            onEnter(ev);
          }

          ev.preventDefault();
        }
      }}
      rows={rows}
      value={value ? value : ""}
      onChange={onChange}
      label={label}
      variant={variant}
    />
  );
}
