import OnboardingBoilerplate from "./OnboardingBoilerplate";
import BasicInfoEditor from "../core/BasicInfoEditor";

export default function AskBasicInfo() {
  return (
    <OnboardingBoilerplate
      title="Complete Signup"
      currentSection={"Basic Info"}
    >
      <BasicInfoEditor />
    </OnboardingBoilerplate>
  );
}
