import styled from "styled-components";
import { useContext, useState } from "react";

import isLoggedIn from "../../util/controllers/isLoggedIn.js";

import LoggedOutHomePage from "../homePage/components/LoggedOutHomePage.js";
import CreateContent from "./CreateContent.js";

import LoadingSection from "../../util/components/LoadingSection.js";
import InfoBox from "../../util/components/InfoBox.js";

import Context from "../../Context.js";
import BeautifulTitle from "../../util/components/BeautifulTitle.js";

const Button = styled.button`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid;
  gap: 25px;
  background-color: var(--color);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--bgColor);
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 300px;
  }
`;

const BigButton = styled(Button)`
  width: auto;
  padding: 0 50px;
  flex: unset;
  border: none;
  height: 60px;
  box-shadow: 3px 5px 8px 0px #00000066;

  @media (max-width: 700px) {
    width: calc(100vw - 50px);
  }
`;

const DownloadAppButton = styled(Button)`
  @media (min-width: 950px) {
    display: none;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  padding: 10px 130px;
  padding-top: 20px;
  gap: 70px;

  @media (max-width: 900px) {
    padding: 0;
    margin-top: 30px;
    gap: 30px;
  }
`;

export default function CreatePage() {
  const { loggedInUser } = useContext(Context);

  if (!isLoggedIn()) return <LoggedOutHomePage />;

  if (!loggedInUser) return <LoadingSection />;

  if (loggedInUser.isBanned)
    return (
      <InfoBox>
        You can't access this page because you have been banned.
      </InfoBox>
    );

  return (
    <Main>
      <BeautifulTitle>Create a discussion</BeautifulTitle>

      <CreateContent />
    </Main>
  );
}
