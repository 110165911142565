import { useContext } from "react";
import PopupDialogContext from "./PopupDialogContext";

const usePopupDialog = () => {
  const context = useContext(PopupDialogContext);
  if (!context) {
    throw new Error("usePopupDialog must be used within a PopupDialogProvider");
  }
  return context;
};

export default usePopupDialog;
