import { useContext, useEffect } from "react";

import { styled } from "styled-components";

import ChatMessagesPanel from "./components/chatMessagesPanel/ChatMessagesPanel";
import ChatGroupsPanel from "./components/chatGroupsPanel/ChatGroupsPanel";
import InfoBox from "../../util/components/InfoBox";
import loadChatGroups from "./controllers/api/loadChatGroups";
import loadSelectedChatGroup from "./controllers/api/loadSelectedChatGroup";
import PopupLogin from "../../util/components/core/PopupLogin";
import getSubPath from "../../util/controllers/getSubPath";
import Context from "../../Context";
import ChatMainPanel from "./components/chatMainPanel/ChatMainPanel";

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  height: calc(100vh - var(--mainPadding) - var(--mainPadding));
  height: calc(100svh - var(--mainPadding) - var(--mainPadding));

  height: calc(100% - var(--mainPadding));
  width: calc(100% - var(--mainPadding));
  width: 100%;
  /* margin-right: var(--mainPadding); */
  background: transparent;
  border-radius: 10px;
  gap: 15px;
  overflow: hidden;

  @media (max-width: 1300px) {
    // For ipad and 13inch macbooks
    grid-template-columns: 1.5fr 3fr;
  }

  @media (max-width: 900px) {
    padding: 0;
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
    margin: 0;
    overflow: visible;
    box-shadow: var(--hardShadow);
  }
`;

export default function ChatPage() {
  const { loggedInUser } = useContext(Context);

  let {
    setSelectedChatGroupData,
    setSelectedChatGroupID,
    selectedChatGroupData,
    isConnected,
    friendsLastSeenAt,
    typingData,
  } = window.globalChatSpace;

  let chatGroupID = null;

  if (window.location.href.indexOf("/chat/") !== -1) {
    chatGroupID = getSubPath(1);
  }

  useEffect(() => {
    if (!chatGroupID) {
      setSelectedChatGroupID(null);
      setSelectedChatGroupData(null);
    } else {
      setSelectedChatGroupID(chatGroupID);
    }

    loadSelectedChatGroup(chatGroupID);
  }, [chatGroupID]);

  useEffect(() => {
    return removeSelectedChatGroup;
  }, []);

  const { loggedInUserID } = useContext(Context);

  if (!loggedInUserID) return <PopupLogin />;

  if (!isConnected)
    return (
      <Container>
        <InfoBox>Please! check your internet connection</InfoBox>
        <InfoBox>Trying to reconnected...</InfoBox>
      </Container>
    );

  if (loggedInUser) {
    if (loggedInUser.isBanned)
      return (
        <InfoBox>
          You can't access this page because you have been banned.
        </InfoBox>
      );
  }

  return (
    <Container>
      <ChatGroupsPanel />
      <ChatMainPanel />
    </Container>
  );

  function removeSelectedChatGroup() {
    setSelectedChatGroupData(null);
    setSelectedChatGroupID(null);
  }
}
