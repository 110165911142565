import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import Context from "../../Context";
import NotificationBox from "./NotificationBox";
import InfiniteScroll from "../../util/components/InfiniteScroll";
import LoadingSection from "../../util/components/LoadingSection";
import { serverLine } from "../../util/controllers/serverLine";
import capitalizeFirstLetter from "../../util/controllers/capitalizeFirstLetter";
import FilledButton from "../../util/components/buttons/FilledButton";

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  opacity: 0.5;
`;

export default function NotificationsCore({ scrollData }) {
  const { loggedInUserID, loggedInUser, updateNotificationData } =
    useContext(Context);
  const [res, setRes] = useState([]);
  const [notificationsSeenAt, setNotificationsSeenAt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  let api = "/notifications";

  useEffect(() => {
    setOffset(0);
    setNotificationsSeenAt(null);
    setRes([]);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loggedInUserID) {
      doLoading(true).then(updateNotificationData);
    }
  }, [loggedInUserID]);

  async function doLoading(firstLoad, theOffset = offset) {
    if (firstLoad) theOffset = 0;
    setLoading(true);
    let newRes = await serverLine.get(`${api}/?offset=${theOffset}`);

    if (firstLoad) {
      setNotificationsSeenAt(newRes.notificationsSeenAt);
    }
    if (!newRes.notifs.length) {
      setReachedTheEnd(true);
    }

    if (firstLoad) {
      setRes([...newRes.notifs]);
    } else {
      setRes([...res, ...newRes.notifs]);
    }

    setLoading(false);

    setOffset(theOffset + 1);
    return true;
  }

  if (!loggedInUserID) return "Login to continue";

  if (!loggedInUser) return <LoadingSection />;

  let result = renderResult();

  if (!result.length && !loading) return <Message>No alerts to show</Message>;

  return (
    <InfiniteScroll
      reachedTheEnd={reachedTheEnd}
      loading={loading}
      load={doLoading}
      scrollData={scrollData}
    >
      {result}
    </InfiniteScroll>
  );

  function renderResult() {
    if (!res) return [];
    if (!res.length) return [];
    return res.map((item) => {
      return getNotif({ item });
    });
  }

  function getNotif({ item }) {
    let seen = hasSeen(item.createdAt);

    let notificationText = null;
    let notificationImage = null;
    let notificationKey = item._id;
    let createdAt = item.createdAt;
    let notificationButtons = null;

    if (item.type === "CONTENT_REPORTED") {
      notificationButtons = (
        <>
          <FilledButton href={"/content/" + item.contentID}>
            Visit Content
          </FilledButton>
        </>
      );

      if (item.contentData) {
        notificationText = `Your post: '${
          item.contentData.title
        }' got reported. Reason: ${item.data ? item.data.reason : ""}`;
      } else {
        notificationText = `Your post got reported.`;
      }
    } else if (item.type === "CONCLUSION_REPORTED") {
      notificationButtons = (
        <>
          <FilledButton href={"/content/" + item.contentID}>Visit</FilledButton>
        </>
      );

      if (item.contentData) {
        notificationText = `Your Conclusion on: '${
          item.contentData.title
        }' got reported. Reason: ${item.data ? item.data.reason : ""}`;
      } else {
        notificationText = `Your post got reported.`;
      }
    } else if (item.type === "DISLIKE") {
      notificationButtons = null;

      let senderName = "Someone";

      notificationText = `${senderName} Liked your conclusion.`;
    } else if (item.type === "CHAT_GROUP_KICK_OUT") {
      notificationButtons = null;

      let mainTitle = "";

      if (item.contentData) {
        mainTitle = "'" + item.contentData.title + "'";
      }

      notificationText = `You have been removed from the chat group of ${mainTitle}`;
    } else if (item.type === "LIKE") {
      notificationButtons = (
        <FilledButton href={`/content/${item.contentID}`}>Open</FilledButton>
      );

      let senderName = "Someone";

      let mainTitle = "";

      if (item.contentData) {
        mainTitle = "'" + item.contentData.title + "'";
      }

      if (item.sender) {
        senderName = capitalizeFirstLetter(item.sender.name);
      }

      notificationText = `${senderName} Liked your conclusion on ${mainTitle}`;
    } else if (item.type === "FOLLOW") {
      let senderName = "Someone";

      if (item.sender) {
        senderName = capitalizeFirstLetter(item.sender.name);

        notificationButtons = (
          <FilledButton href={`/${item.sender.username}`}>
            Open Profile
          </FilledButton>
        );
      }

      notificationText = `${senderName} started following you.`;
    } else if (item.type === "PROFILE_REPORTED") {
      notificationButtons = null;

      notificationText = `You got reported. Reason: ${
        item.data ? item.data.reason : ""
      }`;
    } else if (item.type === "CONCLUSION_UN_BANNED") {
      notificationButtons = (
        <>
          <FilledButton href={"/content/" + item.contentID}>
            Visit Content
          </FilledButton>
        </>
      );

      if (item.contentData) {
        notificationText = `Your conclusion on: '${item.contentData.title}' has been un-banned.`;
      } else {
        notificationText = `Your conclusion got un-banned.`;
      }
    } else if (item.type === "CONCLUSION_BANNED") {
      notificationButtons = (
        <>
          <FilledButton href={"/content/" + item.contentID}>
            Visit Content
          </FilledButton>
        </>
      );

      if (item.contentData) {
        notificationText = `Your conclusion on '${item.contentData.title}' has been banned.`;
      } else {
        notificationText = `Your conclusion got banned.`;
      }
    } else if (item.type === "CONTENT_UN_BANNED") {
      notificationButtons = (
        <>
          <FilledButton href={"/content/" + item.contentID}>
            Visit Content
          </FilledButton>
        </>
      );

      if (item.contentData) {
        notificationText = `Your post: '${item.contentData.title}' has been un-banned.`;
      } else {
        notificationText = `Your post got un-banned.`;
      }
    } else if (item.type === "CONTENT_BANNED") {
      notificationButtons = (
        <>
          <FilledButton href={"/content/" + item.contentID}>
            Visit Content
          </FilledButton>
        </>
      );

      if (item.contentData) {
        notificationText = `Your post '${item.contentData.title}' has been banned.`;
      } else {
        notificationText = `Your post got banned.`;
      }
    } else if (item.type === "PROFILE_BANNED") {
      notificationButtons = null;

      notificationText = `You have been banned`;
    } else if (item.type === "PROFILE_UN_BANNED") {
      notificationButtons = null;

      notificationText = `You have been un-banned`;
    } else {
      // notificationImage = item.sender.profileImage;
      notificationButtons = null;
      notificationText = `${item.sender ? item.sender.username : null} - ${
        item.type
      }`;
    }

    return (
      <NotificationBox
        key={notificationKey}
        createdAt={createdAt}
        seen={seen}
        text={notificationText}
        buttons={notificationButtons}
        image={notificationImage}
      />
    );
  }

  function hasSeen(createdAt) {
    let createdAtEpochs = new Date(createdAt).valueOf();
    let notificationsSeenAtDate = new Date(
      notificationsSeenAt ? notificationsSeenAt : 0
    ).valueOf();
    // console.log(createdAtEpochs, notificationsSeenAt);

    return createdAtEpochs < notificationsSeenAtDate;
  }
}
