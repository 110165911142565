import { useContext } from "react";
import styled from "styled-components";
import Context from "../../../../Context";
import MobileChatMainPanel from "./MobileChatMainPanel";
import DesktopChatMainPanel from "./DesktopChatMainPanel";

export default function ChatMainPanel() {
  const { isMobile } = useContext(Context);

  if (isMobile) return <MobileChatMainPanel />;

  return <DesktopChatMainPanel />;
}
