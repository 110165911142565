import { styled } from "styled-components";
import { useContext } from "react";

import BasicInfoEditor from "../../util/components/core/BasicInfoEditor";
import Context from "../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  padding: 50px 0;
  gap: 50px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    padding: 30px 0;
    /* padding: 25px; */
  }
`;

const TopPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const EditSection = styled.div`
  width: 100%;
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  background-color: var(--primarySurface);
  border-radius: 10px;
  padding: 25px;

  @media (max-width: 900px) {
    width: 100%;
    /* padding: 25px; */
  }
`;

const RightButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export default function ProfileEditor() {
  const { loggedInUser } = useContext(Context);

  return (
    <Container>
      <Main>
        <BasicInfoEditor />
      </Main>
    </Container>
  );
}
